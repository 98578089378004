const Projects = ({ data }) => {
  return (
    <section id="portfolio-sec">
      <div className="container">
        <div
          className="row text-center wow bounceIn animated"
          data-wow-duration="1s"
          data-wow-delay=".2s"
        >
          <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 text-center mb-4">
            <h1>RECENT PROJECTS</h1>
          </div>
        </div>
        <div
          className="wow bounceIn animated"
          data-wow-duration="1s"
          data-wow-delay=".4s"
        >
          {data.projects.map((project, idx) => (
            // <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12" key={idx}>
            //   <div className="portfolio-sec-wrap">
            //     <img
            //       className="img-responsive"
            //       src={
            //         project.photo ||
            //         "https://png.pngtree.com/png-vector/20220706/ourmid/pngtree-project-management-png-image_5687733.png"
            //       }
            //       alt=""
            //     />
            //     <div className="overlay">
            //       <div className="portfolio-sec-inner">
            //         <h3>{project.projectName}</h3>
            //         <p>
            //           {project.description.slice(0, 100)}
            //           ...
            //         </p>
            //         {project.projectLink && (
            //           <a className="preview" href={project.projectLink}>
            //             <i className="fa fa-eye"></i>SEE PROJECT{" "}
            //           </a>
            //         )}
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <div className="col-md-6" key={idx}>
              <div className="services-inner-box">
                <h2>{project.projectName}</h2>
                <p
									style={{
										display: "block",
										maxHeight: "9em",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{project.description}
								</p>
                {project.projectLink && (
                  <a className="preview" href={project.projectLink}>
                    <i className="fa fa-eye"></i>SEE PROJECT
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
