import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function AnimateStep(props) {
	const ref = useRef(null);
	const [hasAnimated, setHasAnimated] = useState(false);

	useEffect(() => {
		const element = ref.current;

		if (element && !hasAnimated) {
			gsap.timeline({
				scrollTrigger: {
					trigger: element,
					start: "top center",
					end: "bottom center",
					onEnter: () => {
						gsap.to(ref.current.children, {
							y: 0,
							stagger: 0.03,
							delay: 0.5,
							duration: 0.5,
						});
					},
					once: true,
				},
			});
		}

		setHasAnimated(true);
	}, [hasAnimated]);

	return <div {...props} ref={ref}></div>;
}
