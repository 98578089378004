import Education from "./education";
import Experience from "./experience";
import Skills from "./skills";
import Languages from "./languages";

const About = ({data, subdomain}) => {
  
  return (
    <div className="vg-page page-about" id="about">
          <div className="container py-3">
            <div className="row">
              <div className="col-md-6">
                <div className="img-place wow zoomIn">
                  <img className="profile-pic" src={data.profilePicture} alt=""/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="caption wow fadeInRight">
                  <h2 className="fg-dark">{data.name}</h2>
                  <p>{data.aboutMe}</p>
                  
                </div>
              </div>
            </div>
          </div>


          <div className="container pt-5">
            <div className="row">
           {data.education?.length>0 && Object.keys(data.education[0]).length? <Education data={data}/>:<></>}
            {data.workExperience?.length>0 && Object.keys(data.workExperience[0]).length?<Experience data={data}/>:<></>}
            </div>
          </div>
          {data.languages?.length>0 && Object.keys(data.languages[0]).length?<Languages data={data}/>:<></>}
          {data.skills?.length>0 && Object.keys(data.skills[0]).length?<Skills data={data}/>:<></>}
        </div>
  );
};

export default About;
