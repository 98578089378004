// import data from "../static-data.json";

const Experience = ({ data }) => {
  return (
    <div className="col-md-6 wow fadeInRight" data-wow-delay="200ms">
      <h2 className="fw-normal">Experience</h2>
      <ul className="timeline experience mt-4 pr-md-5">
        {data.workExperience.map((work, idx) => {
          return (
            <li key={idx}>
                  <div className={work.endDate==='Present'?"present title":"title"}>
                    {work.startDate} - {work.endDate}
                  </div>
                  <div className="details">
                    <h5>{work.position}</h5>
                    <small className="fg-theme">{work.companyName}</small>
                    <p>{work.description}</p>
                  </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default Experience;
