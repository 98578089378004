const Footer = ({ data }) => {
  return (
    <div className="vg-footer">
      <h1 className="text-center">{data.name}</h1>
      <div className="container">
        <div className="row">
          <div className="py-3">
            <div className="float-lg-right">
              <p>Follow me</p>
              <hr className="divider" />
              <ul className="list-unstyled">
                {data.profileLink && (
                  <li>
                    <a href={data.profileLink}>LinkedIn</a>
                  </li>
                )}
                {data.instaLink && (
                  <li>
                    <a href={data.instaLink}>Instagram</a>
                  </li>
                )}
                {data.fbLink && (
                  <li>
                    <a href={data.fbLink}>Facebook</a>
                  </li>
                )}
                {data.twitterLink && (
                  <li>
                    <a href={data.twitterLink}>Twitter</a>
                  </li>
                )}
                {data.dribbleLink && (
                  <li>
                    <a href={data.dribbleLink}>Dribble</a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center mt-3">
          <div className="col-12 mb-3">
            <h3 className="fw-normal text-center">Subscribe</h3>
          </div>
          <div className="col-lg-6">
            <form className="mb-3">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email address"
                />
                <input
                  type="submit"
                  className="btn btn-theme no-shadow"
                  value="Subscribe"
                />
              </div>
            </form>
          </div>
        </div> */}
      </div>
      <p style={{ textAlign: "center" }}>
        © {new Date().getFullYear()} All rights reserved by {data.name}
      </p>
    </div>
  );
};

export default Footer;
