

const About = ({ data, subdomain }) => {
  return (
    <section className="section pt-0" id="about">
      <div className="container text-center">
        <div className="about">
          <div className="about-img-holder">
            <img src={data.profilePicture} className="about-img" alt="" />
          </div>
          <div className="about-caption">
            {data.aboutMe ? (
              <>
                <h2 className="section-title mb-3">About Me</h2>
                <p className="mb-0">{data.aboutMe}</p>
              </>
            ) : (
              <></>
            )}
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
