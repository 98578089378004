const Projects = ({ data }) => {
  return (
    <section className="section" id="portfolio">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="section-title">Projects</h2>
          </div>
        </div>
        <div className="row shuffle-wrapper projects">
          <div className="col-md-12">
            {data.projects.map((project, idx) => (
              <div className="full comment_blog_line my-2" key={idx}>
                <div className="row">
                  <div className="col-md-9">
                    <div className="full contact_text">
                      <h3>{project.projectName}</h3>
                      <h4>
                        {project.startDate}-{project.endDate}
                      </h4>
                      <p>{project.description}</p>
                    </div>
                  </div>
                  {project.projectLink && (
                    <div className="col-md-2">
                      <a className="reply_bt" href={project.projectLink}>
                        See Live Demo
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;

