import React from "react";

const Contact = ({ data }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const mailtoLink = generateMailtoLink(formData);
    window.location.href = mailtoLink;
  };

  const generateMailtoLink = (formData) => {
    const firstName = encodeURIComponent(formData.get("firstName"));
    const lastName = encodeURIComponent(formData.get("lastName"));
    const email = encodeURIComponent(formData.get("email"));
    const address = encodeURIComponent(formData.get("address"));
    const message = encodeURIComponent(formData.get("message"));
    const subject = "Contact Form Submission";

    const mailto = `mailto:${data.userEmail}?subject=${subject}&body=Name: ${firstName} ${lastName}%0AEmail: ${email}%0AAddress: ${address}%0AMessage: ${message}`;

    return mailto;
  };

  return (
    <>
      <section className="contact section" id="contact">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="mb-5 mb-lg-0">
                <h3 className="mb-4">Just Say Hi!......</h3>
                <form className="row px-4 px-md-0" onSubmit={handleSubmit}>
                  <div className="col-md-6 contact-from border-bottom pb-4 mb-5">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="John"
                      autoComplete="off"
                      required
                    />
                    <label>First Name</label>
                  </div>
                  <div className="col-md-6 contact-from border-bottom pb-4 mb-5">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Doe"
                      autoComplete="off"
                      required
                    />
                    <label>Last Name</label>
                  </div>
                  <div className="col-md-6 contact-from border-bottom pb-4 mb-5">
                    <input
                      type="email"
                      name="email"
                      placeholder="johndoe@gmail.com"
                      autoComplete="off"
                      required
                    />
                    <label>Mail</label>
                  </div>
                  <div className="col-md-6 contact-from border-bottom pb-4 mb-5">
                    <input
                      type="text"
                      name="address"
                      placeholder="Address"
                      autoComplete="off"
                      required
                    />
                    <label>Address</label>
                  </div>
                  <div className="contact-from border-bottom pb-4 mb-5 w-100">
                    <input
                      type="text"
                      name="message"
                      placeholder="Message"
                      autoComplete="off"
                      required
                    />
                    <label>Message</label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg mt-4"
                  >
                    Submit Now
                  </button>
                </form>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              <div className="text-center text-lg-start">
                <h4 className="mb-4">Social contacts</h4>
                <ul className="list-inline contact-us">
                  <li className="list-inline-item">
                    {data.profileLink && (
                      <a href={data.profileLink}>
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    )}
                  </li>
                  {data.fbLink && (
                    <li className="list-inline-item">
                      <a href={data.fbLink}>
                        <i className="fab fa-facebook"></i>
                      </a>
                    </li>
                  )}
                  {data.twitterLink && (
                    <li className="list-inline-item">
                      <a href={data.twitterLink}>
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                  )}
                  {data.skypeLink && (
                    <li className="list-inline-item">
                      <a href={data.skypeLink}>
                        <i className="fab fa-skype"></i>
                      </a>
                    </li>
                  )}
                  {data.githubLink && (
                    <li className="list-inline-item">
                      <a href={data.githubLink}>
                        <i className="fab fa-github"></i>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p className="text-center mt-3">
        © {new Date().getFullYear()} All rights reserved by {data.name}
      </p>
    </>
  );
};

export default Contact;
