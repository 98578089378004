import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import stylesNew from "../Styles/newLanding.module.css";
import { getUserJson } from "../service/api";
import { login, logout } from "../Features/userSlice";
import { useDispatch } from "react-redux";
import { loginUrl, logoutUrl, getToken } from "../service/api";

export default function Auth() {
	const [userCred, setUserCred] = useState(false);

	const dispatch = useDispatch();

	async function getUserInfo () {
		const response = await getToken();
		if(response?.data?.user){
			localStorage.setItem("credential", response.data.user);
			localStorage.setItem("online", false);
			setUserCred(true)
		}
	}

	const CheckUser = () => {
		const token = localStorage.getItem("credential")
		let decoded;
		if (token){
			decoded = jwt_decode(token);
		}
		
		if (!decoded) return;
		getUserJson(decoded.id, token).then((data) => {
			dispatch(login(data.data.user));

			if (!!data.data.user.json) {
				localStorage.setItem("online", true);
			}
		});
	};

	useEffect(() => {
		const credential = localStorage.getItem("credential");
		if (credential) {
			CheckUser();
			setUserCred(true);
			return;
		} 
		else {
			getUserInfo();
		}
		// document.body.style.overflow = "hidden";
	}, []);

	const Logout = async () => {
		localStorage.clear()
		dispatch(logout());
		window.location.href = "/";
	};

	return (
		<>
			<div>
				{!userCred ? (
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
						>
						<a href={`${loginUrl}`}>
						<button className={stylesNew.login + " btn-hover"}>
							Get Started
						</button>
						</a>
					</div>
				) : (
					<button className={stylesNew.logoutBtn} onClick={Logout}>
						<a href={`${logoutUrl}`}>
							<h3 className={stylesNew.logout}>Logout</h3>
						</a>
					</button>
				)}
			</div>
		</>
	);
}
