const Projects = ({ data }) => {
  return (
    <div className="container-fluid pt-5" id="projects">
      <div className="container">
        <div className="position-relative d-flex align-items-center justify-content-center">
          <h1
            className="display-1 text-uppercase text-white"
            style={{ WebkitTextStroke: "1px #dee2e6" }}
          >
            Projects
          </h1>
          <h1 className="position-absolute text-uppercase text-primary">
            My Projects
          </h1>
        </div>
        <div className="row pr-row project-div" >
          {data.projects.map((project, idx) => (
            <div className="mb-5 all-projects" key={idx}>
              <h3>{project.projectName}</h3>

              <p className="font-weight-medium mb-4">
                {project.description}
                {/* {project.summary.slice(0, 140)}.... */}
              </p>
              {project.projectLink && (
                <a
                  className="btn btn-sm btn-outline-primary py-2"
                  href={project.projectLink}
                >
                  Read More
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
