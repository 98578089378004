const Footer = ({data})=>{
    return(
       
      <footer>
      <div className="row">
        <div className="twelve columns">
          <ul className="social-links">
            {data.fbLink && <li>
              <a href={data.fbLink}>
                <i className="fa fa-facebook"></i>
              </a>
            </li>}
           {data.twitterLink && <li>
              <a href={data.twitterLink}>
                <i className="fa fa-twitter"></i>
              </a>
            </li>}
           {data.googleLink && <li>
              <a href={data.googleLink}>
                <i className="fa fa-google-plus"></i>
              </a>
            </li>}
           {data.profileLink && <li>
              <a href={data.profileLink}>
                <i className="fa fa-linkedin"></i>
              </a>
            </li>}
           {data.instaLink && <li>
              <a href={data.instaLink}>
                <i className="fa fa-instagram"></i>
              </a>
            </li>}
           {data.dribbleLink && <li>
              <a href={data.dribbleLink}>
                <i className="fa fa-dribbble"></i>
              </a>
            </li>}
          {data.skypeLink &&  <li>
              <a href={data.skypeLink}>
                <i className="fa fa-skype"></i>
              </a>
            </li>}
          </ul>
        </div>

        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <i className="icon-up-open"></i>
          </a>
        </div>
      </div>
      <p>© {new Date().getFullYear()} All rights reserved by {data.name}</p>
    </footer>
    )
}

export default Footer;