import styles from "../Styles/newLanding.module.css";
import Auth from "../Components/Auth";
import ExtensionIcon from "@mui/icons-material/Extension";
import { useSelector } from "react-redux";
import { useState } from "react";
import { chromeExtensionUrl } from "../service/api";
import { ReactComponent as Logo } from "./logo.svg";

export default function Navigation() {
	const user = useSelector((state) => state.user);
	const [toggle, setToggle] = useState(false);

	const toggleNav = () => {
		setToggle(!toggle);
	};

	return (
		<header className={styles.navigation}>
			<a className={styles.logoContainer} href="/">
				{/* <img
					src="/Assets/logo.png"
					alt="logo"
					className={styles.logoimg}
				/> */}
				<Logo className={styles.logoimg} />
				<h3 className={styles.siteName}>SitesGPT</h3>
			</a>
			<div className={styles.hamburger} onClick={toggleNav} open={toggle}>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>

			<div className={toggle ? styles.hamNavButtons : styles.navButtons}>
				{user.online && (
					<>
						<a
							className={styles.userBlogNav}
							href={
								"https://" +
								user.subdomain +
								".sitesgpt.co.in/blogs"
							}
						>
							Your Blogs
						</a>
						<a className={styles.selectTheme} href="/select-theme">
							Select Theme
						</a>
					</>
				)}

				{/* {user && (
					<a
						className={styles.extension}
						href={`${chromeExtensionUrl}`}
					>
						Download Extension
						<ExtensionIcon />
					</a>
				)} */}
				<a
					href="https://www.producthunt.com/posts/sitesgpt-beta?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-sitesgpt&#0045;beta"
					target="_blank"
					style={{
						width: "250px",
							height: "50px",
					}}
				>
					<img
						src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=403219&theme=light"
						alt="sitesGPT&#0032;&#0045;&#0032;Beta - Build&#0032;your&#0032;own&#0032;personal&#0032;website&#0032;in&#0032;less&#0032;than&#0032;2&#0032;minutes | Product Hunt"
						style={{
							width: "250px",
							height: "50px",
							filter: " hue-rotate(191deg)",
							mixBlendMode: "multiply",
						}}
						width="250"
						height="54"
					/>
				</a>
				<Auth />
			</div>
		</header>
	);
}
