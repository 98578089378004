const Languages = ({ data }) => {
	return (
		<section id="languages" className="section languages-section border-d">
			<div className="section-block skills-block">
				<div className="container-fluid">
					<div className="section-header">
						<h2>
							<strong className="color">Languages</strong> I Understand
						</h2>
					</div>

					<div className="row">
						<div className="col-md-8" style={{
							display: "grid",
							gridTemplateColumns: "repeat(auto-fit, minmax(25rem, 1fr))",
						}}>
							{data.languages.map((lang, idx) => (
								<div className="skill" key={idx}>
									<h4>{lang.language}</h4>
									{/* <div className="bar">
                    <div className="percent" style={{ width: "100%" }}></div>
                  </div> */}
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Languages;
