import Navigation from "./components/navigation";
import Header from "./components/header";
import About from "./components/about";
import Skills from "./components/skills";
import Languages from "./components/languages";
import Education from "./components/education";
import Experience from "./components/experience";
import Recommendation from "./components/recommendation";
import Projects from "./components/projects";
import Contact from "./components/contact";
import filterTag from "./functions/filterTag";
import fillContent from "./functions/fillContent";
import { useEffect } from "react";
import "./css/style.scss";
import "./plugins/font-awesome/fontawesome.min.scss";

function Theme9({ data, subdomain }) {
  useEffect(() => {
    let metaTags = document.getElementsByTagName("meta");
    let description = filterTag(metaTags, "description");
    let author = filterTag(metaTags, "author");

    fillContent(author, data.name);
    fillContent(description, data.about);
    document.title = data.name;
  }, [data.name, data.about]);
  return (
    <div id="theme9">
      <Navigation data={data} />

      <Header data={data} subdomain={subdomain} />

      {data.aboutMe?<About data={data} />:<></>}

      {data.skills?.length>0 && Object.keys(data.skills[0]).length > 0?<Skills data={data} />:<></>}

      {data.languages?.length>0 && Object.keys(data.languages[0]).length > 0?<Languages data={data} />:<></>}

      {data.education?.length>0 && Object.keys(data.education[0]).length > 0?<Education data={data} />:<></>}

      {data.workExperience?.length>0 && Object.keys(data.workExperience[0]).length > 0?<Experience data={data} />:<></>}

      {data.recommendations?.length>0 && Object.keys(data.recommendations[0]).length > 0?<Recommendation data={data} />:<></>}
      {data.projects?.length>0 && Object.keys(data.projects[0]).length > 0?<Projects data={data} />:<></>}

      <Contact data={data} />
    </div>
  );
}

export default Theme9;
