import styles from "../Styles/newLanding.module.css";
import { chromeExtensionUrl } from "../service/api";
import Image from "./Image";
import Video from "./Video";
import AnimateStep from "./AnimateSteps";

export default function Steps() {
	return (
		<AnimateStep className={styles.steps} id="steps">
			<div className={styles.step}>
				<div>
					{/* <Image src="/Assets/step1.png" alt="step1" /> */}
					<Image src="/Assets/step1.gif" alt="step1" />
				</div>
				<h2>install the extension</h2>
			</div>
			<div className={styles.step}>
				<div>
					{/* <Image
						// style={{
						// 	width: "70%",
						// }}
						style={{
							margin: "auto",
							width: "auto",
							aspectRatio: "1/1",
							// transform: "translate(0, 1.5rem)",
						}}
						// src="/Assets/step22.png"
						alt="step2"
					/> */}
					<Image src="/Assets/step2.gif" alt="step1" />
				</div>
				<h2>
					use extension from{" "}
					<a
						href="https://linkedin.com/in/me"
						target="_blank"
						style={{
							color: "var(--secondary-clr)",
							fontSize: "1em",
							fontFamily: "var(--secondary-font)",
							fontWeight: "semibold",
							textDecoration: "underline",
						}}
					>
						linkedin
					</a>
				</h2>
			</div>
			<div className={styles.step}>
				<div>
					{/* <Image src="/Assets/step3.png" alt="step3" /> */}
					<Image src="/Assets/step3.gif" alt="step3" />
				</div>
				<h2>Choose Your Template</h2>
			</div>
		</AnimateStep>
	);
}
