const About = ({ data, subdomain }) => {
  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic" src={data.profilePicture} alt="" />
        </div>

        <div className="nine columns main-col">
          {data.aboutMe ? (
            <>
              <h2>About Me</h2>

              <p className="about-me">{data.aboutMe}</p>
            </>
          ) : (
            <></>
          )}
          
        </div>
      </div>
    </section>
  );
};

export default About;
