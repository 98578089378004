import Loading from "../Components/Loading";
import styles from "../Styles/UserOnMobile.module.css";
import LaptopIcon from "@mui/icons-material/Laptop";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import { useSelector } from "react-redux";

export default function UserOnMobile() {
	const user = useSelector((state) => state.user);

	return (
		<div className={styles.UserOnMobileBody}>
			<Loading />
			<h1 className={styles.UserOnMobileTitle}>
				Please use a <br/> LAPTOP or DESKTOP <br/> to use this website.
			</h1>
			<div className={styles.UserOnMobileIcons}>
				<LaptopIcon className={styles.UserOnMobileIcon} />
				<MobileOffIcon className={styles.UserOnMobileIcon} />
			</div>
		</div>
	);
}
