import Education from "./education";
import Experience from "./experience";

const About = ({ data }) => {
  return (
    <div className="container-fluid py-5" id="about">
      {data.aboutMe ? (
        <div className="container">
          <div className="position-relative d-flex align-items-center justify-content-center">
            <h1
              className="display-1 text-uppercase text-white"
              style={{ WebkitTextStroke: "1px #dee2e6" }}
            >
              About
            </h1>
            <h1 className="position-absolute text-uppercase text-primary">
              About Me
            </h1>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-5 pb-4 pb-lg-0">
              <img
                className="img-fluid rounded w-100 about-img"
                src={data.profilePicture}
                alt=""
              />
            </div>
            <div className="col-lg-7">
              <p className="about-me">{data.aboutMe}</p>
              <a href="#contact" className="btn btn-outline-primary mr-4">
                Hire Me
              </a>
            
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="container-fluid py-5" id="qualification">
        <div className="container">
          <div className="position-relative d-flex align-items-center justify-content-center">
            <h1
              className="display-1 text-uppercase text-white"
              style={{ WebkitTextStroke: "1px #dee2e6" }}
            >
              Quality
            </h1>
            <h1 className="position-absolute text-uppercase text-primary">
              Education & Expericence
            </h1>
          </div>
          <div className="row">
            {data.education?.length>0 && Object.keys(data.education[0]).length > 0?<Education data={data} />:<></>}
            {data.workExperience?.length>0 && Object.keys(data.workExperience[0]).length > 0?<Experience data={data} />:<></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
