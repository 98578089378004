// import data from "../static-data.json";

const Projects2 = ({ data }) => {
  return (
    <div className="vg-page page-portfolio" id="portfolio">
      <div className="line">
        <h1 className="text-center fg-dark wow fadeInUp">My Projects</h1>
        <div className="margin">
          {data.projects.map((project, idx) => (
            <div className="s-12 m-6 l-6 my-2" key={idx}>
              <div className="s-12 l-2">
                <div className="news-date">
                  <p className="month">{project.startDate} - {project.endDate}</p>
                </div>
              </div>
              <div className="s-12 l-10">
                <div className="news-text">
                  <h4>{project.projectName}</h4>
                  <p>
                    {project.description}
                  </p>
                  {project.projectLink && (
                  <a
                    href={project.projectLink}
                  >
                    SEE LIVE DEMO
                  </a>
                )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects2;
