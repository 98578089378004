import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'; // npm package for creating portals
import styles from "../Styles/Modal.module.css"

var Timer;
const PopupModal = () => {
  const [counter, setCounter] = useState(6);
  const [show, setShow] = useState(false)
  const [cancel, setCancel] = useState(false);
  const credential = localStorage.getItem("credential");
  const online = localStorage.getItem("online");
  
  const closeModal = () => {
    setShow(false)
    setCancel(true)
    document.body.style.overflow = "auto";
    clearInterval(Timer)
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);
    Timer = timer;
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (counter === 1 && !cancel) {
      clearInterval(Timer);
      window.location.href = `${process.env.REACT_APP_CHROME_EXTENSION_URL}`;
    }
  }, [counter]);

	useEffect(() => {
		if(credential !== null && online === "false"){
			setShow(true);
      setCancel(false);
      document.body.style.overflow = "hidden";
		} else {
      setCancel(true)
    }
	}, [credential, online]);

  
  return show? createPortal(
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2>Go to Extension Download page</h2>
        <p>{`Redirecting in ${counter} seconds...`}</p>
        <button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </button>
      </div>
    </div>,
    document.body
  ): "";
};

export default PopupModal;