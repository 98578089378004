import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  // console.log("rendering..", data.recommendations);

  return (
    <div className="vg-page p-0 page-testimonial" id="testimonial">
      <Carousel
        autoPlay
        infiniteLoop
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        {data.recommendations.map((recommendation, idx) => {
          return (
            <div className="owl-carousel testi-carousel" key={idx}>
              <div className="item">
                <p>
                  {recommendation.recommendationText}
                </p>
                {/* <span className="iconic">
                  <span className="ti-quote-left"></span>
                </span> */}
                <div className="thumb-profile">
                  <img
                    src={
                      recommendation.profileImg ||
                      "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                    }
                    className="rounded-circle"
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                    alt=""
                  />
                </div>
                <h4>{recommendation.name}</h4>
                <h6>{recommendation.description}</h6>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Recommendation;
