import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styles from "../Styles/newLanding.module.css";

const HomePageReview = ({ data }) => {
  const iframeWrapper = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div className="section-block testimonials-block">
      <h1 className={styles.reviewTitle}>Reviews</h1>

      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showArrows={true} // Set showArrows to true to display navigation arrows
        emulateTouch={true} // Enable touch swipe support
        showIndicators={false} // Disable bottom indicators
        showStatus={false}
        centerMode={true} // Enable center mode
        centerSlidePercentage={33.3} // Adjust the visible percentage of each slide
        swipeScrollTolerance={5} // Adjust the swipe scroll tolerance
      >
        <div style={iframeWrapper}>
          <iframe
            style={{
              border: "none",
              height: "300px",
              position: "relative",
              width: "80%",
              margin: "auto",
              borderRadius: "5px",
            }}
            src="https://cards.producthunt.com/cards/reviews/642798?v=1"
          ></iframe>
        </div>
        <div style={iframeWrapper}>
          <iframe
            style={{
              border: "none",
              height: "300px",
              position: "relative",
              width: "80%",
              margin: "auto",
              borderRadius: "15px",
            }}
            src="https://cards.producthunt.com/cards/reviews/642714?v=1"
          ></iframe>
        </div>
        <div style={iframeWrapper}>
          <iframe
            style={{
              border: "none",
              height: "300px",
              position: "relative",
              width: "80%",
              margin: "auto",
              borderRadius: "15px",
            }}
            src="https://cards.producthunt.com/cards/reviews/645094?v=1"
          ></iframe>
        </div>
        {/* Add more card items here */}
      </Carousel>
    </div>
  );
};

export default HomePageReview;
