import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <section id="testimonial-main" className="mt-5">
      <div className="overlay">
        <div className="container">
          <div className="text-center">
            <h1>CLIENT TESTIMONIALS</h1>
          </div>
          <Carousel infiniteLoop showStatus={false} showThumbs={false} showArrows={false}>
            {data.recommendations.map((recommendation, idx) => (
              <div
                className="text-center mb-4"
                key={idx}
                // style={{ wordBreak: "break-word" }}
              >
                <h4 className="font-weight-light">
                  {recommendation.recommendationText}
                </h4>
                <div>
                  <img
                    className="img-fluid rounded-circle mx-auto mb-3"
                    src={
                      recommendation.profileImg ||
                      "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                    }
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                    alt=""
                  />
                  <h5 className="font-weight-bold m-0">
                    {recommendation.name}
                  </h5>
                  <h6 className="font-weight-bold m-0">
                    {recommendation.description}
                  </h6>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Recommendation;
