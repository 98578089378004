const Skills = ({ data }) => {
  return (
    <div className="container py-5">
      <h1 className="text-center fw-normal wow fadeIn">My Skills</h1>
      <div className="py-3">
        <div className="px-lg-3 all-skills">
          {data.skills.map((skill, idx) => {
            return (
              <div
                className="progress-wrapper wow fadeInUp skill"
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <em className="lead caption">{skill.skill}</em>
                  <small className="float-left">{skill.edorsments}</small>
                
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Skills;
