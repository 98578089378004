// import { useEffect } from "react";
// import data from "../static-data.json";
import Navigation from "./navigation";
// import { useState } from "react";

const Header = ({data}) => {
  return (
    <>
      <div className="vg-page page-home" id="home">
        <Navigation data={data} />

        <div className="caption-header text-center wow zoomInDown">
          <h5 className="fw-normal">Welcome</h5>
          <h1 className="fw-light mb-4">
            I'm <b className="fg-theme name">{data.name}</b>
          </h1>
          {data.description?<h2 className="badge desc">{data.description}</h2>:<></>}
        </div>
        <div className="floating-button">
          <span className="ti-mouse"></span>
        </div>
      </div>
    </>
  );
};

export default Header;
