import { useState } from "react";
import jwt_decode from "jwt-decode";
import { setUserTheme } from "../service/api";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function Colors({ selected, subdomain, id, user }) {
	// const [primary, setPrimary] = useState("");
	// const [secondary, setSecondary] = useState("");
	// const [ternary, setTernary] = useState("");
	// const [quartnary, setQuartnary] = useState("");
	const token = localStorage.getItem(['credential']);
	// console.log(user)
	const [collapse, setCollapse] = useState(false);

	// console.log(primary);

	const selectTemplete = () => {
		setUserTheme({
			id: user.id,
			theme: parseInt(id),
		}, token).then((res) => {
			console.log(res.status);
			if (res.status) {
				window.location.href = "/preview/" + id;
			}
		});
	};

	return (
		<div
			id="swatch"
			collapse={collapse ? "true" : "false"}
			style={{
				background:
					"",
				backgroundColor: "white",
				border: "1px solid white",
				padding: "0 2em",
			}}
		>
			<div
				style={{
					opacity: "0.6",
					fontFamily: "var(--primary-font)",
				}}
			>
				Only visible to you
			</div>
			{/* <div style={{margin:"auto"}}>
				<input
					type="color"
					value={primary}
					onChange={(e) => setPrimary(e.target.value)}
				/>
				<input
					type="color"
					collapse={collapse ? "true" : "false"}
					value={secondary}
					onChange={(e) => setSecondary(e.target.value)}
				/>
				<input
					type="color"
					collapse={collapse ? "true" : "false"}
					value={ternary}
					onChange={(e) => setTernary(e.target.value)}
				/>
				<input
					type="color"
					collapse={collapse ? "true" : "false"}
					value={quartnary}
					onChange={(e) => setQuartnary(e.target.value)}
				/>
			</div> */}

			{!selected ? (
				<>
					<div
						style={{
							margin: "auto",
							opacity: "0.6",
							fontFamily: "var(--primary-font)",
						}}
					>
						Do you like this???
					</div>
					<button
						id="finish"
						style={{
							fontFamily: "var(--secondary-font)",
							color: "var(--font-color-primary)",
						}}
						onClick={selectTemplete}
					>
						Select This
					</button>
				</>
			) : (
				<div
					style={{
						margin: "auto",
							color: "var(--font-color-primary)",
							
						fontFamily: "var(--primary-font)",
					}}
				>
					Your site is live at:{" "}
					<a
						href={`https://${subdomain}.sitesgpt.co.in`}
						style={{
							margin: "auto",
							color: "var(--quad-clr)",
							textDecoration: "none",
							boxShadow: "0px 3px 0px -2px var(--quad-clr)",
						}}
					>
						<i>{`https://${subdomain}.sitesgpt.co.in`}</i>
					</a>{" "}
				</div>
			)}
		</div>
	);
}
