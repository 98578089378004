const Experience = ({ data }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="row justify-content-around experience">
          <div className="col-lg-12 text-center">
            <h2 className="section-title">Experience</h2>
          </div>
          {data.workExperience.map((work, idx) => {
            return (
              <div className="col-lg-3 col-md-4 text-center mt-5" key={idx}>
                <img src={work.companyLogo} alt="icon" />
                <p className="mb-0">
                  {work.startDate} - {work.endDate}
                </p>
                <h6>{work.position}</h6>
                <h4 className="text-light">{work.companyName}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Experience;
