const Skills = ({data}) => {
  return (
    <div className="container mt-5">
      <h1 className="text-center fg-dark wow fadeInUp">My Skills</h1>
      <div className="col all-skills">
        {data.skills.map((skill,idx) => {
          return (
            <div className="progress-wrapper wow fadeInUp skill d-flex flex-column justify-content-between" key={idx}>
              <em className="lead caption">{skill.skill}</em>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Skills;
