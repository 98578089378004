import { useEffect, useState } from "react";
const Navigation = ({ data }) => {
	const [visible, setVisible] = useState(false);
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (document.documentElement.scrollTop > 400) {
				setVisible(true);
			} else {
				setVisible(true);
			}
		});
	}, []);

	return (
		<>
			<a
				className="btn-back_to_top"
				style={{
					visibility: visible ? "visible" : "hidden",
					transform: "translate(-0.5em, -5em)",
				}}
				href="#home"
			>
				<span className="ti-arrow-up"></span>
			</a>

			<div className="minibar">
				<div className="header">
					<div className="brand"></div>
				</div>
				<div className="content">
					<ul className="main-menu">
						<li className="menu-item">
							<a href="#home" className="menu-link">
								<span className="icon ti-home"></span>
								<span className="caption">Home</span>
							</a>
						</li>
						<li className="menu-item">
							<a href="/blogs" className="menu-link">
								<span className="icon ti-write"></span>
								<span className="caption">Blogs</span>
							</a>
						</li>
						{data.about && (
							<li className="menu-item">
								<a href="#about" className="menu-link">
									<span className="icon ti-user"></span>
									<span className="caption">About</span>
								</a>
							</li>
						)}
						{data.recommendations?.length > 0 &&
							Object.keys(data.recommendations[0]).length > 0 && (
								<li className="menu-item">
									<a
										href="#testimonial"
										className="menu-link"
									>
										<span className="icon ti-file"></span>
										<span className="caption">
											Testimonials
										</span>
									</a>
								</li>
							)}
						{data.projects?.length > 0 &&
							Object.keys(data.projects[0]).length > 0 && (
								<li className="menu-item">
									<a href="#portfolio" className="menu-link">
										<span className="icon ti-briefcase"></span>
										<span className="caption">
											Portfolio
										</span>
									</a>
								</li>
							)}
						<li className="menu-item">
							<a href="#contact" className="menu-link">
								<span className="icon ti-location-pin"></span>
								<span className="caption">Contact</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};
export default Navigation;
