import Loading from "../Components/Loading";
import styles from "../Styles/newLanding.module.css";
import HomeIcon from "@mui/icons-material/Home";

export default function error({errorMessage}) {
	return (
		<div
			className={styles.error}
			style={{ overflowY: "hidden" }}
		>
			
			<Loading />
			<h1>{errorMessage ?? "500 Internal Error"}</h1>
			<a href="/" className={styles.downloadExtensionOffline + " btn-hover"}>
				Go Back to Home
				<HomeIcon />
			</a>
		</div>
	);
}
