const Awards = ({ data }) => {
	return (
		<section id="awards" className="section awards-section border-d">
			<div className="section-block pricing-block">
				<div className="section-header">
					<h2>
						Some of My{" "}
						<strong className="color">Awards / Competitions</strong>
					</h2>
				</div>

				<div className="row">
					<div
						className="col-8"
						style={{
							display: "grid",
							gridTemplateColumns:
								"repeat(auto-fit, minmax(25rem, 1fr))",
						}}
					>
						{data.awards.map((award, idx) => (
							<div className="col" key={idx}>
								<div
									className="p-table"
									style={{ height: "100%" }}
								>
									<div className="price">
										<h1>&#127942;</h1>
										<h3
											className="color"
											style={{
												display: "block",
												maxHeight: "3.5em",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{award.title}
										</h3>
										<span className="display-6">
											{award.institute} - {award.date}
										</span>
									</div>
									<br />
									<div className="items">
										<span
											style={{
												display: "block",
												height: "4em",
												overflow: "hidden",
												whiteSpace: "wrap",
												textOverflow: "ellipsis",
											}}
										>
											{award.description}
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Awards;
