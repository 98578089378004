const Education = ({data}) => {
  return (
    <div className="col-lg-6">
              <h3 className="mb-4">My Education</h3>
              <div className="border-left border-primary pt-2 pl-4 ml-2 education">
                {data.education.map((ed,idx) => (
                  <div className="position-relative mb-4" key={idx}>
                    <i
                      className="far fa-dot-circle text-primary position-absolute"
                      style={{ top: "2px", left: "-32px" }}
                    ></i>
                    <h5 className="font-weight-bold mb-1">
                      {ed.qualification}
                    </h5>
                    <p className="mb-2">
                      <strong>{ed.instituteName}</strong> |{" "}
                      <small>
                        {ed.startDate} - {ed.endDate}
                      </small>
                    </p>
                    <p>{ed.description}</p>
                  </div>
                ))}
              </div>
            </div>
  );
};

export default Education;
