const About = ({data}) => {
  return ( 
    <section className="page-title-alt section position-relative" id="about">
    {data.aboutMe?<div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="font-tertiary">About Me</h1>
        </div>
      </div>
      <div className="row">
       <div className="col-md-8">
          <p>{data.aboutMe}</p>
        </div>
        <div className="col-md-4  text-center drag-lg-top">
          <div className="shadow-down mb-4">
            <img
              src={data.profilePicture}
              alt="author"
              className="img-fluid w-100  profile-pic rounded-lg border-thick border-white"
            />
          </div>
          <h4>{data.name}</h4>
        </div>
      </div>
    </div>:<div className=" col-md-4 text-center drag-lg-top d-block mx-auto">
          <div className="shadow-down mb-4">
            <img
              src={data.profilePicture}
              alt="author"
              className="img-fluid w-100  profile-pic rounded-lg border-thick border-white"
            />
          </div>
          <h4>{data.name}</h4>
        </div>}
  </section>
  );
};

export default About;
