const Skills = ({data}) => {
  return (
    <section className="section" id="resume">
      <div className="container">
        <div className="row  all-skills">
          <div className="col-lg-12 text-center">
            <h2 className="section-title">Skills</h2>
            </div>
            {data.skills.map((skill,idx) => (
              <div className="col-lg-3 col-sm-6 mb-4 mb-lg-0" key={idx}>
                <div className="card shadow text-center">
                  <div
                    className="position-relative rounded-top progress-wrapper"
                    data-color="#41228e" style={{backgroundColor: "rgb(65, 34, 142)"}}
                  >
                    <div className="wave" data-progress="60%" style={{bottom:"60%"}}></div>
                  </div>
                  <div className="card-footer bg-white">
                    <h4 className="card-title">{skill.skill}</h4>
                    <small>{skill.edorsments}</small>
                  </div>
                </div>
            </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
