import leafOrange from "../images/illustrations/leaf-orange.png";
import dotsOrange from "../images/illustrations/dots-orange.png";
import yellowLeaf from "../images/illustrations/leaf-yellow.png";
import cyanLeaf from "../images/illustrations/leaf-cyan.png";
import cyanLeaf2 from "../images/illustrations/leaf-cyan-2.png";
import pinkLeaf from "../images/illustrations/leaf-pink-round.png";
import groupDots from "../images/illustrations/dots-group-orange.png";


const Header = ({data}) => {
  return (
    <section
      className="hero-area bg-primary"
      id="parallax"
      style={{ overflow: "hidden" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <h1 className="text-white font-tertiary">
              Hi! I’m <br /> {data.name}
              <br />
            </h1>{" "}
            {data.description?<h4 className="tagline">{data.description}</h4>:<></>}
          </div>
        </div>
      </div>
      <div className="layer" id="l3">
        <img src={leafOrange} alt="bg-shape" />
      </div>
      <div className="layer" id="l4">
        <img src={dotsOrange} alt="bg-shape" />
      </div>
      <div className="layer" id="l5">
        <img src={yellowLeaf} alt="bg-shape" />
      </div>
      <div className="layer" id="l6">
        <img src={cyanLeaf} alt="bg-shape" />
      </div>
      <div className="layer" id="l7">
        <img src={groupDots} alt="bg-shape" />
      </div>
      <div className="layer" id="l8">
        <img src={pinkLeaf} alt="bg-shape" />
      </div>
      <div className="layer" id="l9">
        <img src={cyanLeaf2} alt="bg-shape" />
      </div>
      <ul className="list-unstyled social-links ml-5 mt-3 position-relative zindex-1">
        <li className="mb-3">
          <a className="text-white" href="#">
            <i className="ti-facebook"></i>
          </a>
        </li>
        <li className="mb-3">
          <a className="text-white" href="#">
            <i className="ti-instagram"></i>
          </a>
        </li>
        <li className="mb-3">
          <a className="text-white" href={data.profileLink}>
            <i className="ti-linkedin"></i>
          </a>
        </li>
        <li className="mb-3">
          <a className="text-white" href="#">
            <i className="ti-twitter"></i>
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Header;
