const Languages = ({data})=>{
    return (
         <section id="lang-sec">
        <div className="overlay">
          <div className="container">
            <div
              className="col-lg-4 col-md-4 col-sm-4  wow bounceIn animated"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              <h1>LANGUAGES</h1>
              <div className="color-strip"></div>
            </div>
            <div className="p-0">
              <div
                className="row mt-3 gy-5 gx-4 all-langs wow bounceIn animated"
                data-wow-duration="1s"
                data-wow-delay="1s"
              >
                {data.languages.map((lang,idx) => (
                  <div className="lang m-0" key={idx}>
                    <div>
                      <h4 className="font-weight-bold mb-2">{lang.language}</h4>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Languages;