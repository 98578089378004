import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <section className="testimonial section pt-0" id="testimonial">
      <div className="container">
        <div className="row mb-3  text-center">
          <div className="col-lg-6 col-12 mx-auto ">
            <div className="section-title mb-md-5">
              <h2>
                Our{" "}
                <strong>
                  Testimonial
                  <svg
                    width="288"
                    height="20"
                    viewBox="0 0 288 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5542 13.3865C8.73165 13.3396 16.0446 12.002 22.9875 10.3399C27.8345 9.17953 32.4949 9.48038 37.3191 8.46843C57.1014 4.31885 77.8606 4.85593 97.98 5.19219C133.674 5.78877 169.523 5.16219 205.291 5.76C232.293 6.2113 259.037 9.10976 285.99 9.56025"
                      stroke="#FF5733"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </strong>
              </h2>
            </div>
          </div>
        </div>
        <div
          className="row align-items-center justify-content-center bg-primary aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div>
            <Carousel
              autoPlay
              infiniteLoop
              showArrows={false}
              showThumbs={false}
              showStatus={false}
            >
              {data.recommendations.map((recommendation, idx) => (
                <div className="testimonial-left-slider-item" key={idx}>
                  <svg
                    className="quotation"
                    width="60"
                    height="61"
                    viewBox="0 0 60 61"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.1044 14.1903C25.6048 13.561 26.2341 11.7702 25.5564 10.3181L23.1847 5.33265C22.5071 3.92946 20.8614 3.29975 19.4093 3.92946C15.2951 5.67195 11.7618 7.94686 8.95443 10.7058C5.51786 13.9483 3.19455 17.6752 1.93609 21.7894C0.677632 25.9525 0 31.6151 0 38.827V53.6866C0 55.2838 1.30686 56.5907 2.90414 56.5907H21.9262C23.5235 56.5907 24.8304 55.2838 24.8304 53.6866V34.6644C24.8304 33.0667 23.5235 31.7603 21.9262 31.7603H12.8266C12.9234 26.8712 14.0851 22.9511 16.2148 19.9981C17.9573 17.6273 20.571 15.6912 24.1044 14.1903Z"
                      fill="#DBDBDB"
                    />
                    <path
                      d="M58.2764 14.1903C59.7769 13.5611 60.4056 11.7702 59.7285 10.3181L57.3568 5.38159C56.6791 3.97792 55.0335 3.34869 53.5814 3.97792C49.5156 5.72041 46.0301 7.99532 43.1749 10.7058C39.7378 13.9972 37.3666 17.7242 36.1077 21.8384C34.8492 25.9037 34.2205 31.5672 34.2205 38.8276V53.6871C34.2205 55.2844 35.5273 56.5913 37.1246 56.5913H56.1467C57.744 56.5913 59.0509 55.2844 59.0509 53.6871V34.665C59.0509 33.0672 57.744 31.7609 56.1467 31.7609H46.9987C47.0955 26.8717 48.2576 22.9516 50.3868 19.9986C52.1293 17.6274 54.743 15.6913 58.2764 14.1903Z"
                      fill="#DBDBDB"
                    />
                  </svg>
                  <div className="ms-lg-5 ms-md-4 mb-4">
                    <div className="ms-md-4">
                      <p>{recommendation.recommendationText}</p>
                    </div>
                    <div className="d-flex ms-md-4 align-items-center justify-content-center">
                      <img
                        className="img rounded-circle"
                        src={
                          recommendation.profileImg ||
                          "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                        }
                        style={{
                          height: "80px",
                          width: "80px",
                        }}
                        alt="img"
                      />
                      <div className="text ms-3">
                        <h5 className="mt-4 mb-1">{recommendation.name}</h5>
                        <em>{recommendation.description}</em>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div>
            <div className="testimonial-right-slider mb-md-0 mb-5"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recommendation;
