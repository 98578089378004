const Languages = ({data})=>{
    return (
      <section className="languages section">
      <div className="container">
        <div
          className="row justify-content-between aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="col-lg-5">
            <div className="section-title mb-5">
              <h2>
                <strong>
                  Languages.....
                  <svg
                    width="243"
                    height="19"
                    viewBox="0 0 243 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.21709 14.5397C22.6251 11.094 43.5278 9.994 63.9195 6.62078C83.198 3.43169 103.311 4.48586 122.822 4.14529C147.108 3.72138 171.148 2.75291 195.34 5.24786C205.654 6.31158 217.3 7.49114 227.395 9.95137C230.185 10.6315 240.184 10.4221 241.39 12.7333"
                      stroke="#FF5733"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </strong>{" "}
              </h2>
            </div>
          </div>
          <div className="col-lg-6 all-langs">
            {
                data.languages.map((lang,idx) => 
                  <div className="mb-5" key={idx}>
                    <h5 className="mb-3 pb-3 border-bottom">{lang.language}</h5>
                    </div>
                  )
            }
          </div>
        </div>
      </div>
    </section>
    )
}

export default Languages;