import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <div className="container-fluid py-5" id="testimonial">
      <div className="container">
        <div className="position-relative d-flex align-items-center justify-content-center">
          <h1
            className="display-1 text-uppercase text-white"
            style={{ WebkitTextStroke: "1px #dee2e6" }}
          >
            testimonial
          </h1>
          <h1 className="position-absolute text-uppercase text-primary">
            Clients Say
          </h1>
        </div>
        <Carousel
          autoPlay
          infiniteLoop
          showStatus={false}
          showArrows={false}
          showThumbs={false}
        >
          {data.recommendations.map((recommendation, idx) => (
            <div className="text-center mb-4" key={idx}>
              <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>
              <h4 className="font-weight-light mb-4">
                {recommendation.recommendationText}
              </h4>
              <div>
                <img
                  className="img-fluid rounded-circle mx-auto mb-3"
                  src={
                    recommendation.profileImg ||
                    "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                  }
                  style={{ width: "80px", height: "80px" }}
                  alt=""
                />
                <h5 className="font-weight-bold m-0">{recommendation.name}</h5>
                <h6 className="mb-0">{recommendation.description}</h6>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Recommendation;
