import { useRef } from "react";
import gsap from "gsap";
import styles from "../Styles/newLanding.module.css";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import AnimateFAQ from "./AnimateFAQ";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	backgroundColor: "transparent",
	maxWidth: "60rem",
	margin: "0 auto",
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary expandIcon={<AddIcon />} {...props} />
))(({ theme }) => ({
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(45deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
	"& h2": {
		fontWeight: "600",
		fontFamily: "var(--secondary-font)",
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(4),
}));

export default function FAQ() {
	return (
		<div className={styles.faqContainer}>
			<AnimateFAQ>
				<h1 className={styles.faqTitle}>FAQ</h1>

				<Accordion defaultExpanded={true}>
					<AccordionSummary aria-controls="faq1" id="faq1">
						<h2 className={styles.faqQuestion}>
							What is SitesGPT?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
						It's a tool that saves you time by automatically creating websites, 
						incorporating your content, generating resumes, and crafting blogs,
						all within a span of less than 2 minutes.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary aria-controls="faq2" id="faq2">
						<h2 className={styles.faqQuestion}>
							Is it free?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
						Currently, it is free for beta users. Enjoy the free access for now!
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary aria-controls="faq3" id="faq3">
						<h2 className={styles.faqQuestion}>
							Can we download our website?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
						Yes, we have good news for you! In just a week, we will 
						be introducing the ability to download your website. 
						With a minimal charge, you can obtain a downloadable 
						ZIP file containing your website's HTML, CSS, and JS.
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary aria-controls="faq4" id="faq4">
						<h2 className={styles.faqQuestion}>
							Can we edit our data?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
						Absolutely! Starting next week, you will have the 
						ability to edit your data, photos, and everything 
						else on your website. Stay tuned for this exciting feature!
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary aria-controls="faq5" id="faq5">
						<h2 className={styles.faqQuestion}>
							Are we limited to only a few themes?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
						No, not at all! We understand the importance of 
						customization, which is why we are working diligently 
						to bring in a wide range of beautiful themes. These 
						themes will cater to different user requirements 
						and demands. Expect them to be available very soon!
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary aria-controls="faq6" id="faq6">
						<h2 className={styles.faqQuestion}>
						Can we bind in a custom domain?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
					At the moment, you can redirect your website using DNS. 
					However, we have great news for you! We are actively 
					working on providing the functionality to connect your 
					website with a custom domain. This feature will be available soon, 
					so stay tuned!
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary aria-controls="faq7" id="faq7">
						<h2 className={styles.faqQuestion}>
						Can we change resume themes?
						</h2>
					</AccordionSummary>
					<AccordionDetails>
					Absolutely! In our upcoming version update next week, we will be introducing 
					ten unique resume formats. This will give you the flexibility to switch between 
					different themes and find the perfect style that suits your needs. Get ready for 
					an enhanced resume-building experience!
					</AccordionDetails>
				</Accordion>
			</AnimateFAQ>
		</div>
	);
}
