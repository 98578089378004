import { useEffect } from "react";
import fillContent from "./functions/fillContent";
import filterTag from "./functions/filterTag";
import About from "./components/about";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Header from "./components/header";
import Projects from "./components/projects";
import Recommendation from "./components/recommendation";
import "./css/bootstrap.scss";
import "./css/virtual.scss";
import "./css/topbar.scss";
import "./css/maicons.scss";
import "./css/themify-icons.scss";
// import "./functions/topbar-virtual"
// require("bootstrap");
// import "./functions/jquery-3.5.1.min.js";
// import "./css/bootstrap.modules.css";
// import "./css/virtual.modules.css";
// import "./css/topbar.virtual.modules.css";
// import "./css/maicons.modules.css";
// import "./css/themify-icons.modules.css";

function Theme6({ data, subdomain }) {
  useEffect(() => {
    let metaTags = document.getElementsByTagName("meta");
    let description = filterTag(metaTags, "description");
    let author = filterTag(metaTags, "author");

    fillContent(author, data.name);
    fillContent(description, data.about);
    document.title = data.name + " | SitesGPT";
  }, [data.name, data.about]);
  return (
    <div id="theme6">
      <div className="theme-red">
        <Header data={data} />
        <About data={data} subdomain={subdomain}/>

        {data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 ? <Projects data={data} /> : <></>}

        {data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 ? (
          <Recommendation data={data} />
        ) : (
          <></>
        )}

        <Contact data={data} />

        <Footer data={data} />
      </div>
    </div>
  );
}

export default Theme6;
