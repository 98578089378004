import eduBg from "../images/backgrounds/education-bg.png";

const Education = ({data}) => {
  return (
    <section className="section position-relative">
      <div className="container">
        <div className="row education">
          <div className="col-lg-12 text-center">
            <h2 className="section-title">Education</h2>
          </div>
          {data.education.map((ed,idx) => (
            <div className="col-lg-6 col-md-6 mb-80" key={idx}>
              <div className="d-flex">
                <div className="mr-lg-5 mr-3">
                  <i className="ti-medall icon icon-light icon-bg bg-white shadow rounded-circle d-block"></i>
                </div>
                <div>
                  <p className="text-dark mb-1">
                    {ed.startDate}-{ed.endDate}
                  </p>
                  <h4>{ed.qualification}</h4>
                  <p className="mb-0 text-light">{ed.instituteName}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <img className="img-fluid edu-bg-image w-100" src={eduBg} alt="bg" />
    </section>
  );
};

export default Education;
