const Skills = ({data})=>{
    return(
      <section id="skill-sec">
      <div className="overlay">
        <div className="container">
          <div
            className="col-lg-4 col-md-4 col-sm-4  wow bounceIn animated"
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <h1>CHECKOUT MY</h1>
            <h1>SKILLS</h1>
            <div className="color-strip"></div>
          </div>
          <div className="p-0">
            <div
              className="row all-skills wow bounceIn animated"
              data-wow-duration="1s"
              data-wow-delay="1s"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(10rem, 1fr))",
                columnGap: "1rem",
              }}
            >
              {data.skills.map((skill, idx) => (
                <div  className="skills" key={idx} style={{
                  display: "flex",
                }}>
                    <h4 className="font-weight-bold mb-4">{skill.skill}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}

export default Skills;