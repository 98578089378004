import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./Styles/Root.css";
import "./Styles/App.css";
import Landing from "./Pages/Landing";
import Selection from "./Pages/SelectTheme";
import Preview from "./Pages/Preview";
import PrivayPolicy from "./Pages/PrivacyPolicy";
import store from "./store";
import { Provider } from "react-redux";
import Blog from "./Pages/Blog";
import Error from "./Pages/Error";
import DiscoverBlogs from "./Pages/DiscoverBlogs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserOnMobile from "./Pages/UserOnMobile";

let userOnMobile = false;

if (
	navigator.userAgent.match(/Android/i) ||
	navigator.userAgent.match(/webOS/i) ||
	navigator.userAgent.match(/iPhone/i) ||
	navigator.userAgent.match(/iPad/i) ||
	navigator.userAgent.match(/iPod/i) ||
	navigator.userAgent.match(/BlackBerry/i) ||
	navigator.userAgent.match(/Windows Phone/i)
) {
	if (window.location.pathname !== "/mobile") {
		window.location.href = "/mobile";
		userOnMobile = true;
	}
}
else{
  if (window.location.pathname === "/mobile") {
    window.location.href = "/";
    userOnMobile = false;
  }
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <Landing />,
		errorElement: <Error />,
	},
	{
		path: "/mobile",
		element: <UserOnMobile />,
		errorElement: <Error />,
	},
	{
		path: "/select-theme",
		element: <Selection />,
		errorElement: <Error />,
	},
	{
		path: "/select-theme/*",
		element: <Selection />,
		errorElement: <Error />,
	},
	{
		path: "/preview/:id",
		element: <Preview />,
		errorElement: <Error />,
	},
	{
		path: "/privacy-policy",
		element: <PrivayPolicy />,
		errorElement: <Error />,
	},
	{
		// path: "/discover",
		// element: <DiscoverBlogs />,
		// errorElement: <Error />,
	},
	{
		// path: "/discover/blog/:id",
		// element: <Blog />,
		// errorElement: <Error />,
	},
	{
		path: "/select-theme-redirect/*",
		element: <Selection isRedirectFromExtension={true} />,
		errorElement: <Error />,
	},
	{
		path: "*",
		element: <Error errorMessage={"404 Page Not Found"} />,
		errorElement: <Error />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);

function App() {
	return (
		<Provider store={store}>
			<React.StrictMode>
				<ToastContainer />
				{/* <PopupModal /> */}
				<GoogleOAuthProvider clientId="434943018309-gef0hb44jt8ia7ggfuhifq757mmhgik9.apps.googleusercontent.com">
					<RouterProvider router={router} />
				</GoogleOAuthProvider>
			</React.StrictMode>
		</Provider>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
