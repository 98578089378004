const Contact = ({ data }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const mailtoLink = generateMailtoLink(formData);
    window.location.href = mailtoLink;
  };

  const generateMailtoLink = (formData) => {
    const name = encodeURIComponent(formData.get("Name"));
    const email = encodeURIComponent(formData.get("Email"));
    const subject = encodeURIComponent(formData.get("Subject"));
    const message = encodeURIComponent(formData.get("Message"));

    const mailto = `mailto:${data.userEmail}?subject=${subject}&body=Name: ${name}%0AEmail: ${email}%0ASubject: ${subject}%0AMessage: ${message}`;

    return mailto;
  };
    return(
      <div className="vg-page page-contact" id="contact">
      <div className="container-fluid">
        <div className="text-center wow fadeInUp">
          <div className="badge badge-subhead">Contact</div>
        </div>
        <h1 className="text-center fw-normal wow fadeInUp">Get in touch</h1>
        <div className="row py-5">
          <div className="col-lg">
            <form className="vg-contact-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="col-12 mt-3 wow fadeInUp">
                  <input
                    className="form-control"
                    type="text"
                    name="Name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="col-6 mt-3 wow fadeInUp">
                  <input
                    className="form-control"
                    type="text"
                    name="Email"
                    placeholder="Email Address"
                  />
                </div>
                <div className="col-6 mt-3 wow fadeInUp">
                  <input
                    className="form-control"
                    type="text"
                    name="Subject"
                    placeholder="Subject"
                  />
                </div>
                <div className="col-12 mt-3 wow fadeInUp">
                  <textarea
                    className="form-control"
                    name="Message"
                    rows="6"
                    placeholder="Enter message here.."
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-theme mt-3 wow fadeInUp ml-1"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    )
}
export default Contact;