const Header = ({data})=>{
    return(
        <header id="home" className="header">
        <div className="overlay"></div>
        <div className="header-content container">
          <h1 className="header-title">
            <span className="up">HI!</span>
            <span className="down">I am {data.name}</span>
          </h1>
          {data.description ? <p className="header-subtitle">{data.description}</p>:<></>}
          <a className="btn text-white btn-primary" href={data.projects?.length>0 && Object.keys(data.projects[0]).length > 0?"#projects":"#about"}>
            Visit My {data.projects?.length>0 && Object.keys(data.projects[0]).length > 0?'Works':'Profile'}
          </a>
        </div>
      </header>
    )
}

export default Header;
