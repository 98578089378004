const Experience = ({ data }) => {
  // console.log(data.workExperience);
  let companyNames = [];
  let el = data.workExperience;
  companyNames = el.map((work) => {
    return work.companyName;
  });

  const uniqueSet = new Set(companyNames);

  const filteredElements = companyNames.filter((currentValue) => {
    if (uniqueSet.has(currentValue)) {
      uniqueSet.delete(currentValue);
    } else {
      return currentValue;
    }
  });
  // console.log(filteredElements);

  return (
    <div className="row work">
      <div className="three columns header-col">
        <h1>
          <span>Work</span>
        </h1>
      </div>

      <div className="nine columns main-col">
        {data.workExperience.map(
          (work, idx) => (
            filteredElements.includes(work.companyName) ? (
              <div className="rep-col" key={idx}>
              <img
              src={
                work.companyLogo ||
                "https://www.kindpng.com/picc/m/179-1791617_microsoft-office-save-icon-office-symbol-black-and.png"
              }
              className="resume-img"
              alt={work.companyName}
            />
            <div className="row item">
                <div className="twelve columns">
                  <h3>{work.companyName}</h3>

                  <p className="info">
                    {work.position}
                    <span>&bull;</span>{" "}
                    <em className="date">
                      {work.startDate? work.startDate: work.duration}
                      {work.endDate? `- ${work.endDate}`: ""}
                    </em>
                  </p>
                  <p>{work.description}</p>
                </div>
              </div>
              </div>
            ) : (
            <div className="rep-col" key={idx}>
              <img
                src={
                  work.companyLogo ||
                  "https://www.kindpng.com/picc/m/179-1791617_microsoft-office-save-icon-office-symbol-black-and.png"
                }
                className="resume-img"
                alt={work.companyName}
              />
              <div className="row item">
                <div className="twelve columns">
                  <h3>{work.companyName}</h3>

                  <p className="info">
                    {work.position}
                    <span>&bull;</span>{" "}
                    <em className="date">
                      {work.startDate} - {work.endDate}
                    </em>
                  </p>
                  <p>{work.description}</p>
                </div>
              </div>
            </div>
          )
          )
        )}
      </div>
    </div>
  );
};
export default Experience;
