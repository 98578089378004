const Education = ({ data }) => {
	return (
		<section
			className="section education-section border-d timeline-block"
			id="education"
		>
			<div className="row section-block education-block">
				<div className="section-header">
					<h2>
						My <strong className="color">Education</strong>
					</h2>
				</div>

				<ul className="timeline">
					{data.education.map((ed, idx) => (
						<li key={idx}>
							<div className="timeline-content">
								<h4>{ed.instituteName}</h4>

								<em>
									<span>{ed.qualification}</span>
									<span>
										{ed.startDate}-{ed.endDate}
									</span>
								</em>

								<p>{ed.description}</p>
							</div>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default Education;
