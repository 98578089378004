import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <div className="section-block testimonials-block">
      <div className="container-fluid">
        <div className="section-header">
          <h2>
            <strong className="color">Testimonials</strong>
          </h2>
        </div>

        {/* <div className="testimonials-slider">
          {data.recommendations.map((recommendation, idx) => (
            <div className="testimonial" key={idx}>
              <div className="icon">
                <i className="ion-quote"></i>
              </div>

              <p>{recommendation.recommendationText}</p>

              <div className="author">
                <h4>{recommendation.name}</h4>
              </div>
            </div>
          ))}
        </div> */}
        <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} showStatus={false}>
          {data.recommendations.map((recommendation, idx) => (
            <div className="testimonial" key={idx}>
              <div className="icon">
                <i className="ion-quote"></i>
              </div>

              <p>{recommendation.recommendationText}</p>

              <div className="author">
                <h4>{recommendation.name}</h4>
                <h6 className="mb-0">{recommendation.description}</h6>
              <img
                  className="img-fluid rounded-circle"
                  src={
                    recommendation.profileImg ||
                    "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                  }
                  style={{ width: "80px", height: "80px" }}
                  alt=""
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Recommendation;
