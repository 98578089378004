const About = ({data}) => {
  return ( 
    <section className="has-bg-color" id="about">
    <div className="container">
      <div className="row  justify-content-center align-items-center">
        <div className="col-md-6">
          <div
            className="me-4 aos-init aos-animate"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <h2 className="about-header">About Me</h2>
          </div>
        </div>
        <div
          className=" col-md-6 aos-init aos-animate"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <p className="mb-0 about-me">{data.aboutMe}</p>
        </div>
      </div>
    </div>
  </section>
  );
};

export default About;
