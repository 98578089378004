const Awards = ({data})=>{
    return(
      <section className="section awards-section border-d" id="awards">
      <div className="container text-center">
        <h1
          className="section-title mb-4 wow bounceIn animated"
          data-wow-duration="1s"
          data-wow-delay=".2s"
        >
          Awards
        </h1>
        <div className="row">
					<div
						className="col-12"
						style={{
							display: "grid",
							gridTemplateColumns:
								"repeat(auto-fit, minmax(20rem, 1fr))",
						}}
					>
						{data.awards.map((award, idx) => (
							<div className="col" key={idx}>
								<div
									className="p-table"
									style={{ height: "100%" }}
								>
									<div className="price">
										<h1>&#127942;</h1>
										<h3
											className="color"
											style={{
												display: "block",
												maxHeight: "3.5em",
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{award.title}
										</h3>
										<span className="display-6">
											{award.institute} - {award.date}
										</span>
									</div>
									<br />
									<div className="items">
										<span
											style={{
												display: "block",
												height: "4em",
												overflow: "hidden",
												whiteSpace: "wrap",
												textOverflow: "ellipsis",
											}}
										>
											{award.description}
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
        {/* <div
          className="pricing-wrapper all-awards wow bounceIn animated"
          data-wow-duration="1s"
          data-wow-delay=".2s"
        >
          {data.awards.map((award,idx) => (
            <div className="pricing-card" key={idx}>
              <div className="pricing-card-header">
                <h1>&#127942;</h1>
                <h3>{award.title}</h3>
                <span className="display-6">
                  {award.institute} - {award.date}
                </span>
              </div>
              <div className="pricing-card-body mt-2">
                <p>{award.description}</p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </section>
    )
}

export default Awards;