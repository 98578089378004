const About = ({ data, subdomain }) => {
  return (
    <section id="about" className="section about-section border-d">
      <div className="section-block about-block">
        <div className="container-fluid">
          {data.aboutMe ? (
            <>
              <div className="section-header">
                <h2>
                  <strong className="color">About Me</strong>
                </h2>
              </div>
              <div>
                <div className="about-text">
                  <p>{data.aboutMe}</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          
        </div>
      </div>

      
    </section>
  );
};

export default About;
