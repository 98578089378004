import Navigation from "../Components/Navigation";
import Hero from "../Components/Hero";
import Steps from "../Components/Steps";
import styles from "../Styles/newLanding.module.css";
import { useEffect } from "react";
import Stats from "../Components/Stats";
import Comparison from "../Components/ComparisonTable";
import FAQ from "../Components/FAQ1";
import PopupModal from "../Components/PopupModal";
import Footer from "../Components/Footer";
import HomePageReview from "../Components/HomePageReview";

export default function Landing() {

	useEffect(() => {
		document.title = "SitesGPT";
	}, []);

	return (
		<div id="landing" className={styles.langingPageBody}>
			<PopupModal />
			<Navigation />
			<Hero />
			<Steps />
			<Stats/>
			<HomePageReview/>
			<Comparison />
			<FAQ />

			<Footer />
		</div>
	);
}
