const HireMe = ({ data }) => {
  return (
    <section id="hire-sec">
      <div className="overlay">
        <div className="container">
          <div
            className="row text-center pad-top-bottom wow bounceIn animated"
            data-wow-duration="1s"
            data-wow-delay=".2s"
          >
            <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2">
              <h1>CONTACT ME</h1>
              <br />
            </div>
          </div>
          <div
            className="row  move-me wow bounceIn animated"
            data-wow-duration="1s"
            data-wow-delay=".4s"
          >
            <div className="col-lg-6  col-md-6  col-sm-6" style={{margin:"auto", textAlign:"center"}}>
              <form>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    placeholder="Your Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    placeholder="Your Email"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    required="required"
                    className="form-control"
                    style={{ minHeight: "100px" }}
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="form-group">
                  <a href="#hire-sec" className="btn custom-btn-one ">
                    CONTACT ME
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      <p style={{textAlign:"center"}}>
        © {new Date().getFullYear()} All rights reserved by {data.name}
      </p>
      </div>
      <div className="move-me">
        <a href="#home-sec" className="scrollup">
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
    </section>
  );
};

export default HireMe;
