const Projects = ({ data }) => {
	return (
		<section id="projects" className="section projects-section border-d">
			<div className="row section-block projects-block">
				<div className="section-header">
					<h2>
						my<strong className="color mx-2"> Projects</strong>
					</h2>
				</div>
				<div
					id="portfolio-wrapper"
					style={{
						display: "grid",
						gridTemplateColumns:
							"repeat(auto-fit, minmax(25rem, 1fr))",
					}}
				>
					{data.projects.map((project, idx) => (
						<div
							id={"modal-" + project.projectName}
							className="popup-modal"
							key={idx}
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							{project.skills && (
								<div className="skills-box">
									{project.skills
										.split("Skills:")[1]
										.trim()
										.split("·")
										.map((skill, idx) => (
											<span
												key={idx}
												className="badge text-bg-primary"
											>
												{skill}
											</span>
										))}
								</div>
							)}

							<div
								className="description-box"
								style={{ height: "fit-content" }}
							>
								<h3
									style={{
										display: "block",
										maxHeight: "3.5em",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{project.projectName}
								</h3>
								<p>
									{project.startDate} - {project.endDate}
								</p>
								<p
									style={{
										display: "block",
										maxHeight: "3.5em",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{project.description}
								</p>
							</div>

							{project.projectLink && (
								<div className="link-box">
									<a href={project.projectLink}>Details</a>
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Projects;
