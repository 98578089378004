import { useEffect } from "react";
import filterTag from "./functions/filterTag";
import fillContent from "./functions/fillContent";
import "./css/font-awesome.min.scss";
import "./css/styles.scss";
// import "./js/main";
import Navigation from "./components/navigation";
import Header from "./components/header";
import About from "./components/about";
import Skills from "./components/skills";
import Recommendation from "./components/recommendation";
import Projects from "./components/projects";
import Contact from "./components/contact";
import Footer from "./components/footer";

function Theme3({ data, subdomain }) {
  useEffect(() => {
    let metaTags = document.getElementsByTagName("meta");
    let description = filterTag(metaTags, "description");
    let author = filterTag(metaTags, "author");

    fillContent(author, data.name);
    fillContent(description, data.about);
    document.title = data.name + " | SitesGPT";
  }, [data.name, data.about]);
  return (
    <div data-spy="scroll" data-target=".navbar" data-offset="51" id="theme3">
      <Navigation data={data} />
      <Header data={data} subdomain={subdomain} />
      <About data={data} />
      {data.skills?.length > 0 && Object.keys(data.skills[0]).length > 0 ? <Skills data={data} /> : <></>}
      {data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 ? <Recommendation data={data} /> : <></>}
      {data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 ? <Projects data={data} /> : <></>}
      <Contact data={data} />
      <Footer data={data} />
    </div>
  );
}

export default Theme3;
