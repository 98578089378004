// import template1 from "../../public/Templates/template1/index.html";
// import template1 from "./index.html";

import PreviewHeader from "../Components/PreviewHeader";
import React, { useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Theme5 from "../Themes/Theme5/Theme5";
import Theme6 from "../Themes/Theme6/Theme6";
import Theme7 from "../Themes/Theme7/Theme7";
import Theme8 from "../Themes/Theme8/Theme8";
import Theme9 from "../Themes/Theme9/Theme9";
import Theme4 from "../Themes/Theme4/Theme4";
import Theme1 from "../Themes/Theme1reference/Theme1";
// import Theme1 from "../Themes/Theme1/Theme1";
import Theme3 from "../Themes/Theme3/Theme3";
import Theme2 from "../Themes/Theme2/Theme2";
// import data from "./data.json";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { checkUserData, getUserJson } from "../service/api";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../Features/userSlice";

const TemplateRender = ({ id,data }) => {
  switch (id) {
    case 1:
      return <Theme1 data={data} />;
    case 2:
      return <Theme2 data={data} />;
    case 3:
      return <Theme3 data={data} />;
    case 4:
      return <Theme4 data={data} />;
    case 5:
      return <Theme5 data={data} />;
    case 6:
      return <Theme6 data={data} />;
    case 7:
      return <Theme7 data={data} />;
    case 8:
      return <Theme8 data={data} />;
    case 9:
      return <Theme9 data={data} />;
    default:
      return <div>Invalid ID</div>;
  }
};

export default function Preview() {
  const { id } = useParams();
  var templateId = parseInt(id);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);

  const CheckUser = () => {
		const token = localStorage.getItem("credential")
		let decoded;
		if (token){
			decoded = jwt_decode(token);
		}
		
		if (!decoded) return;

		getUserJson(decoded.id, token).then((data) => {
      const user = data.data.user;
      setUserData({ ...user, json: { ...user.json, userEmail: user.email, name: user.json?.profileName }});
			if (!!data.data.user.json) {
				localStorage.setItem("online", true);
			}
		});
	};
//   const [data, setData] = useState(null);

//   useEffect(() => {
//     // get user-data using email
//     if (!localStorage.getItem("credential")) window.location.href = "/";
//     const decoded = jwt_decode(localStorage.getItem("credential"));
//     if (!decoded) return;
//     const email = decoded.email;

//     checkUserData({ email: email }).then((res) => {
//       if (!res.status) {
//         window.location.href = "/";
//       }
//     });

//     getUserJson({ email: email }).then((res) => {
//       setData(res.data.user);
//     });
//   }, []);

useEffect(() => {
  const credential = localStorage.getItem("credential");
  if (credential) {
    CheckUser();
  } 
  else {
    window.location.href="/"
  }
  // document.body.style.overflow = "hidden";
}, []);


  return (
    <>
      {userData && (
        <>
          <PreviewHeader
            selected={userData.theme === parseInt(id)}
            subdomain={userData.json?.profileLink?.split("/").reverse()[1]}
            id={id}
            user={userData}
          />
          {userData && id && <TemplateRender id={templateId} data={userData?.json} />}{" "}
        </>
      )}
    </>
  );
}
