import React from "react";

const Contact = ({ data }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const mailtoLink = generateMailtoLink(formData);
    window.location.href = mailtoLink;
  };

  const generateMailtoLink = (formData) => {
    const name = encodeURIComponent(formData.get("name"));
    const email = encodeURIComponent(formData.get("email"));
    const message = encodeURIComponent(formData.get("message"));

    const mailto = `mailto:${data.userEmail}?subject=Contact%20Form%20Submission&body=Name: ${name}%0AEmail: ${email}%0AMessage: ${message}`;

    return mailto;
  };
    return(
      <section
      className="section section-on-footer"
      style={{backgroundImage:"../images/backgrounds/bg-dots.png"}}
      id="contact"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="section-title">Contact Info</h2>
          </div>
          <div className="col-lg-8 mx-auto">
            <div className="bg-white rounded text-center p-5 shadow-down">
              <h4 className="mb-80">Contact Form</h4>
              <form action="#" className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    className="form-control px-0 mb-4"
                    required
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    className="form-control px-0 mb-4"
                    required
                  />
                </div>
                <div className="col-12">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control px-0 mb-4"
                    placeholder="Type Message Here"
                    required
                  ></textarea>
                </div>
                <div className="col-lg-6 col-10 mx-auto">
                  <button className="btn btn-primary w-100">send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    )
}
export default Contact;