import { useEffect, useState } from "react";

const Navigation = ({ data }) => {
  const [float, setFloat] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 600) {
        setFloat(true);
      } else {
        setFloat(false);
      }
    });
  }, []);
  return (
    <nav id="nav-wrap" className={float ? "show opaque" : "abs"}>
      <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
        Show navigation
      </a>
      <a className="mobile-btn" href="#" title="Hide navigation">
        Hide navigation
      </a>

      <ul id="nav">
        <li>
          <a className="smoothscroll" href="#home">
            Home
          </a>
        </li>
        <li>
          <a className="smoothscroll" href="/blogs">
            Blogs
          </a>
        </li>
        <li>
          <a className="smoothscroll" href="#about">
            About
          </a>
        </li>
        {(data.skills?.length > 0 ||
          data.education?.length > 0 ||
          data.languages?.length > 0 ||
          data.workExperience?.length > 0) && (
          <li>
            <a className="smoothscroll" href="#resume">
              Resume
            </a>
          </li>
        )}
        {data.projects?.length > 0 && (
          <li>
            <a className="smoothscroll" href="#portfolio">
              Works
            </a>
          </li>
        )}
        {data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 && (
          <li>
            <a className="smoothscroll" href="#testimonials">
              Testimonials
            </a>
          </li>
        )}
        <li>
          <a className="smoothscroll" href="#contact">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};
export default Navigation;
