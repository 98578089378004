const Contact = ({ data }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const mailtoLink = generateMailtoLink(formData);
    window.location.href = mailtoLink;
  };

  const generateMailtoLink = (formData) => {
    const name = encodeURIComponent(formData.get("name"));
    const subject = encodeURIComponent(formData.get("subject"));
    const message = encodeURIComponent(formData.get("message"));

    const mailto = `mailto:${data.userEmail}?subject=${subject}&body=Name: ${name}%0ASubject: ${subject}%0AMessage: ${message}`;

    return mailto;
  };

  return (
    <section id="contact" className="section contact-section border-d">
      <div className="section-block contact-block">
        <div className="container-fluid">
          <div className="section-header">
            <h2>
              Contact <strong className="color">Me</strong>
            </h2>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="contact-form">
                <form
                  id="contact-form"
                  data-toggle="validator"
                  onSubmit={handleSubmit}
                >
                  <div id="contact-form-result"></div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                          name="name"
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>

                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          required
                        />
                        <div className="help-block with-errors"></div>
                      </div>
                    </div> */}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      name="subject"
                      required
                    />
                    <div className="help-block with-errors"></div>
                  </div>

                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      name="message"
                      rows="5"
                      required
                    ></textarea>
                    <div className="help-block with-errors"></div>
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn-custom btn-color">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="footer-text">
        © {new Date().getFullYear()} All rights reserved by {data.name}
      </p>
    </section>
  );
};

export default Contact;
