import { useState } from "react";
import Loading from "./Loading";

export default function Image(props) {
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);

	const handleLoad = () => {
		setLoaded(true);
	};

	const handleError = () => {
		setLoaded(true);
		setError(true);
	};

	return (
		<>
			{error && loaded ? (
				<img
					alt="default image"
					src="/Assets/default.png"
					style={{
						width: "100%",
					}}
					loading="lazy"
				/>
			) : (
				<img
					{...props}
					onLoad={handleLoad}
					loading="lazy"
					onError={handleError}
					style={{
						visibility: loaded ? "visible" : "hidden",
					}}
				/>
			)}

			{!loaded && !error && <Loading style={{ margin: "auto", opacity: "0.25" }} /> }
		</>
	);
}
