const Languages = ({ data }) => {
  return (
    <div className="row lang">
      <div className="three columns header-col">
        <h1>
          <span>Languages</span>
        </h1>
      </div>

      <div className="nine columns main-col">
        <div className="all-bars">
          <ul className="all-langs">
            {data.languages.map((lang, idx) => (
              <li key={idx}>
                {/* <span className="lang-bar-expand all"></span> */}
                <em>{lang.language}</em>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Languages;
