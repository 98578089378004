const Skills = ({ data }) => {
	return (
		<section id="skills" className="section skills-section border-d">
			<div className="section-block skills-block">
				<div className="container-fluid">
					<div className="section-header">
						<h2>
							Top <strong className="color">Skills</strong>
						</h2>
					</div>

					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(auto-fit, minmax(25rem, 1fr))",
						}}
					>
						{data.skills.map((skill, idx) => (
							<div className="skill col" key={idx}>
								<h4>{skill.skill}</h4>
								<h6>
									<small>{skill.edorsments}</small>
								</h6>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Skills;
