import React from "react";

const Contact = ({data}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const mailtoLink = generateMailtoLink(formData);
    window.location.href = mailtoLink;
  };

  const generateMailtoLink = (formData) => {
    const name = encodeURIComponent(formData.get("name"));
    const email = encodeURIComponent(formData.get("email"));
    const subject = "Contact Form Submission";
    const message = encodeURIComponent(formData.get("comment"));

    const mailto = `mailto:${data.userEmail}?subject=${subject}&body=Name: ${name}%0AEmail: ${email}%0AMessage: ${message}`;

    return mailto;
  };

  return (
    <section className="section" id="contact">
      <div className="container text-center">
        <p className="section-subtitle">How can you communicate?</p>
        <h6 className="section-title mb-5">Contact Me</h6>
        <form
          action=""
          className="contact-form col-md-10 col-lg-8 m-auto"
          onSubmit={handleSubmit}
        >
          <div className="form-row">
            <div className="form-group col-sm-6">
              <input
                type="text"
                size="50"
                className="form-control"
                placeholder="Your Name"
                name="name"
                required
              />
            </div>
            <div className="form-group col-sm-6">
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email"
                name="email"
                required
              />
            </div>
            <div className="form-group col-sm-12">
              <textarea
                name="comment"
                id="comment"
                rows="6"
                className="form-control"
                placeholder="Write Something"
                required
              ></textarea>
            </div>
            <div className="form-group col-sm-12 mt-3">
              <input
                type="submit"
                value="Send Message"
                className="btn btn-outline-primary rounded"
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
