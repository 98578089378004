const Footer = ({ data }) => {
  return (
    <div className="container">
      <footer className="footer">
        <div className="social-links text-center m-auto ml-sm-auto">
          {data.profileLink && (
            <a href={data.profileLink} className="link">
              <i className="ti-linkedin"></i>
            </a>
          )}
          {data.fbLink && (
            <a href={data.fbLink} className="link">
              <i className="ti-facebook"></i>
            </a>
          )}
          {data.twitterLink && (
            <a href={data.twitterLink} className="link">
              <i className="ti-twitter-alt"></i>
            </a>
          )}
          {data.googleLink && (
            <a href={data.googleLink} className="link">
              <i className="ti-google"></i>
            </a>
          )}
          {data.pinterestLink && (
            <a href={data.pinterestLink} className="link">
              <i className="ti-pinterest-alt"></i>
            </a>
          )}
          {data.instaLink && (
            <a href={data.instaLink} className="link">
              <i className="ti-instagram"></i>
            </a>
          )}
        </div>
      </footer>
        <p style={{textAlign:"center"}}>
          © {new Date().getFullYear()} All rights reserved by {data.name}
        </p>
    </div>
  );
};

export default Footer;
