import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <section className="section" id="testimonial">
      <div className="container text-center">
        <p className="section-subtitle">What Clients Think About Me ?</p>
        <h6 className="section-title mb-6">Testimonial</h6>
        <div className="d-block w-100 mx-auto">
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            autoPlay
            infiniteLoop
          >
            {data.recommendations.map((recommendation, id) => {
              return (
                <div key={id} className="col-lg-12">
                  <div className="testimonial-card">
                    <div className="testimonial-card-img-holder">
                      <img
                        src={
                          recommendation.profileImg ||
                          "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                        }
                        className="testimonial-card-img"
                        alt={recommendation.name}
                      />
                    </div>
                    <div className="testimonial-card-body">
                      <p className="testimonial-card-subtitle">
                        {recommendation.recommendationText}
                      </p>
                      <h5 className="testimonial-card-title">
                        {recommendation.name}
                      </h5>
                      <h6 className="mb-0">{recommendation.description}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default Recommendation;
