const Experience = ({ data }) => {
  return (
    <div className="col-lg-6" id="experience">
      <h3 className="mb-4">My Expericence</h3>
      <div className="border-left border-primary pt-2 pl-4 ml-2 exp-row">
        {data.workExperience.map((work, idx) => (
          <div className="position-relative mb-4 d-block w-100" key={idx}>
            <i
              className="far fa-dot-circle text-primary position-absolute"
              style={{ top: "2px", left: "-32px" }}
            ></i>
            {/* <h5 className="font-weight-bold mb-1">
							{work.position}
						</h5> */}
            <h5 className="font-weight-bold mb-1">{work.position}</h5>
            <div className="mb-2">
              <strong>{work.companyName}</strong> |{" "}
              <small>
                {work.startDate} - {work.endDate}
              </small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Experience;
