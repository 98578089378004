import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <section id="testimonials">
      <div className="text-container">
        <div className="row">
          <div className="two columns header-col">
            <h1>
              <span>Client Testimonials</span>
            </h1>
          </div>
        </div>
      </div>

      <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} showStatus={false}>
        {data.recommendations.map((recommendation, idx) => (
          <div key={idx}>
            <blockquote>
              <p>{recommendation.recommendationText}</p>

              <div className="test-display">
                <img
                  className="img-fluid rounded-circle"
                  src={
                    recommendation.profileImg ||
                    "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                  }
                  style={{ width: "80px", height: "80px" }}
                  alt=""
                />
              </div>
                <h5 style={{color:"white"}} >{recommendation.name}</h5>
                <span style={{color:"white"}} className="mb-0">{recommendation.description}</span>
            </blockquote>
          </div>
        ))}
      </Carousel>
    </section>
  );
};

export default Recommendation;
