const Footer = ({ data }) => {
  return (
    <div className="container-fluid bg-primary text-white">
      <div className="container text-center py-3">
        <div className="d-flex justify-content-center mb-4">
          {data.twitterLink && (
            <a
              className="btn btn-light btn-social mr-2"
              href={data.twitterLink}
            >
              <i className="fa fa-twitter"></i>
            </a>
          )}
          {data.fbLink && (
            <a className="btn btn-light btn-social mr-2" href={data.fbLink}>
              <i className="fa fa-facebook"></i>
            </a>
          )}
          {data.profileLink && (
            <a
              className="btn btn-light btn-social mr-2"
              href={data.profileLink}
            >
              <i className="fa fa-linkedin"></i>
            </a>
          )}
          {data.instaLink && (
            <a className="btn btn-light btn-social" href={data.instaLink}>
              <i className="fa fa-instagram"></i>
            </a>
          )}
        </div>
        <p>
          © {new Date().getFullYear()} All rights reserved by {data.name}
        </p>
      </div>
    </div>
  );
};

export default Footer;
