import styles from "../Styles/newLanding.module.css";
import AnimatedNumbers from "./AnimatedNumbers";

export default function Stats() {
	return (
		<div className={styles.stats}>
			<div className={styles.stat}>
				<img
					style={{
						left: "-50%",
						top: "-50%",
					}}
					src="./Assets/userstats.png"
				/>
				<span>
					<AnimatedNumbers endValue={400} />+
				</span>
				<small>USERS</small>
			</div>
			<div className={styles.stat}>
				<img
					style={{
						top: "0%",
					}}
					src="./Assets/visitorstats.png"
				/>
				<span>
					<AnimatedNumbers endValue={500} />+
				</span>
				<small>VISITORS</small>
			</div>
			<div className={styles.stat}>
				<img
					style={{
						left: "-100%",
						top: "-20%",
						maxWidth: "300%",
					}}
					src="./Assets/templatestats.png"
				/>
				<span>
					<AnimatedNumbers endValue={9} />
				</span>
				<small>TEMPLATES</small>
			</div>
			<div className={styles.stat}>
				<img
					style={{
						left: "0%",
						top: "0%",
					}}
					src="./Assets/sitecreatedstats.png"
				/>
				<span>
					<AnimatedNumbers endValue={500} />+
				</span>
				<small>SITES CREATED</small>
			</div>
		</div>
	);
}
