const Header = ({data}) => {
  return (
    <div
      className="vg-page page-home"
      id="home"
    >
      <div className="caption wow zoomInUp">
        <h1 className="fw-normal">Welcome</h1>
        <h2 className="fw-medium fg-theme">
          I'm <span>{data.name}</span>
        </h2>
       {data.description? <p className="tagline">{data.description}</p>:<></>}
      </div>
    </div>
  );
};

export default Header;
