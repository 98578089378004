import styles from "../Styles/newSelection.module.css";
import { useEffect, useState } from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import Navigation from "../Components/Navigation";
import Image from "../Components/Image";
import { setUserTheme } from "../service/api";
import { useSelector } from "react-redux";
import Error from "./Error";
import { toast } from "react-toastify";
import Footer from "../Components/Footer";

export default function Selection({ isRedirectFromExtension }) {
	const user = useSelector((state) => state.user);
	const token = localStorage.getItem(["credential"]);

	const [show, setShow] = useState(true);
	const [selected, setSelected] = useState(0);

	const templates = [
		{
			name: "Ceevee",
			img: "/Assets/theme1.png",
		},
		{
			name: "N5 Minimal Resume Template",
			img: "/Assets/theme2.png",
		},
		{
			name: "Greeny Green",
			img: "/Assets/theme3.png",
		},
		{
			name: "Computery",
			img: "/Assets/theme4.png",
		},
		{
			name: "Spotlight",
			img: "/Assets/theme5.png",
		},
		{
			name: "Classy",
			img: "/Assets/theme6.png",
		},
		{
			name: "Breeze",
			img: "/Assets/theme7.png",
		},
		{
			name: "Tropical Purple",
			img: "/Assets/theme8.png",
		},
		{
			name: "Under Water",
			img: "/Assets/theme9.png",
		},
	];
	const handleClick = (idx) => {
		setSelected(idx);
	};
	
	const selectTemplete = () => {
		setUserTheme(
			{
				id: user.id,
				theme: selected,
			},
			token
		).then((res) => {
			if (res.status) {
				window.location.href = "https://" + user.subdomain + ".sitesgpt.co.in";
			}
		});
	};

	const isRedirectCheck = () => {
		if (isRedirectFromExtension) {
			toast.success("Redirect Success!", {
				position: "top-left",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	};

	useEffect(() => {
		document.title = "SitesGPT | Template Selection";
		if (!token) {
			setShow(false)
		}
		setSelected(user.theme);
	}, [user]);

	useEffect(() => {
		isRedirectCheck()
	}, []);

	return show ? (
		<div className={styles.selectTemplatebody}>
			<Navigation />

			<div className={styles.title}>
				<h1>Select Theme</h1>
			</div>

			<div className={styles.templates}>
				{templates.map((template, idx) => {
					return (
						<div
							key={idx}
							onClick={() => handleClick(idx + 1)}
							className={
								selected === idx + 1
									? styles.template +
									  " " +
									  styles.templateSelected + " btn-hover"
									: styles.template + " btn-hover"
							}
						>
							{/* <h2 className={styles.templateName}>
								{template.name}
							</h2> */}
							<Image
								className={styles.templateImg}
								src={template.img}
								alt={template.name}
							/>
							<a
								className={styles.previewButton}
								href={`/preview/${idx + 1}`}
								target="_blank"
							>
								<LaunchIcon />
							</a>
							<div className={styles.actions}>
								{/* <a
									className={styles.previewButton}
									href={`/preview/${idx + 1}`}
									target="_blank"
								>
									Preview
									<LaunchIcon />
								</a> */}
								{/* {user.theme !== idx + 1 && (
									<button
										className={styles.selectButton}
										onClick={() => handleClick(idx + 1)}
									>
										Select
									</button>
								)} */}
							</div>
						</div>
					);
				})}
			</div>

			<div className={styles.themeSelectionButton}>
				{selected && user.theme !== selected ? (
					<button className={styles.finish} onClick={selectTemplete}>
						{user.theme !== selected ? "Select" : "Change"}
					</button>
				) : (
					<button className={styles.finish} disabled>
						{user.theme === selected
							? "Already Selected"
							: "Select Theme"}
					</button>
				)}
			</div>

			<Footer />
		</div>
	) : (
		<Error />
	);
}
