import { useEffect, useState } from "react";
import $ from "jquery";
const Navigation = ({ data }) => {
	const [visible, setVisible] = useState(false);
	const [nav, setNav] = useState(false);
	const scrollTop = function () {
		$("html,body").animate({
			scrollTop: 0,
		});
	};
	const toggleNav = function () {
		setNav(!nav);
	};
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (document.documentElement.scrollTop > 100) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		});
	}, []);

	return (
		<header
			className={
				visible
					? "navigation border-bottom fixed-menu"
					: "navigation border-bottom"
			}
		>
			<div
				className="backtotop"
				style={{ display: visible ? "block" : "none", transform: "translate(-1.5em, -5em)" }}
				onClick={scrollTop}
			>
				<i className="fas fa-angle-up"></i>
			</div>
			<nav className="navbar navbar-expand-lg">
				<div className="container">
					<a className="navbar-brand" href="#">
						<i className="far fa-envelope"></i>
						<span className="name">{data.name}</span>
					</a>
					<button
						className={
							nav ? "navbar-toggler" : "navbar-toggler collapsed"
						}
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
						onClick={toggleNav}
					>
						<i className="fas fa-bars"></i>
					</button>
					<div
						className={
							nav
								? "collapse navbar-collapse show"
								: "collapse navbar-collapse"
						}
						id="navbarSupportedContent"
					>
						<ul className="navbar-nav ms-auto mb-lg-0">
							<li className="nav-item">
								<a
									className="nav-link active"
									aria-current="page"
									href="#"
								>
									Home
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link active"
									aria-current="page"
									href="/blogs"
								>
									Blogs
								</a>
							</li>
							{data.about && (
								<li className="nav-item">
									<a
										className="nav-link @@about"
										href={
											data.aboutMe ? "#about" : "#skills"
										}
									>
										About
									</a>
								</li>
							)}
							{data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 && (
								<li className="nav-item">
									<a
										className="nav-link @@portfolio"
										href="#portfolio"
									>
										Portfolio
									</a>
								</li>
							)}
							{data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 && (
								<li className="nav-item">
									<a className="nav-link" href="#testimonial">
										Testimonial
									</a>
								</li>
							)}
							<li className="nav-item">
								<a
									className="nav-link @@contact"
									href="#contact"
								>
									Contact
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	);
};
export default Navigation;
