import ApiRequestService from "./api.service";
import { contentTypes } from "./utils";

const ApiService = new ApiRequestService();
const baseURL = process.env.REACT_APP_SERVER_URL
const extensionUrl = process.env.REACT_APP_CHROME_EXTENSION_URL

export const getUserJson = (id, token) => {
  return ApiService.getApi(`/api/v1/user/${id}`, { ...contentTypes.json, 'Authorization': "Bearer " + token });
};

export const setUserTheme = (body, token) => {
  return ApiService.postApi(`/api/v1/user/theme`,{ ...contentTypes.json, 'Authorization': "Bearer " + token }, body);
};

export const getToken = () => {
  return ApiService.getApi(`/api/v1/oauth/token`);
}

export const getOpenAiApi = (body) => {
  return ApiService.postApi(`/api/json/openai`,{ ...contentTypes.json }, body);
}

export const getUserBlogs = (id, token) => {
  return ApiService.getApi(`/api/v1/blog/${id}`,{ ...contentTypes.json, 'Authorization': "Bearer " + token });
}

export const getBlogById = (id) => {
  return ApiService.getApi(`/api/v1/blog/${id}`,{ ...contentTypes.json});
}

export const getBlogs = (page, itemsPerPage) => {
  return ApiService.getApi(`/api/v1/blog?page=${page}&per_page=${itemsPerPage}`,{ ...contentTypes.json,});
}
export const loginUrl = `${baseURL}/api/v1/auth/google/signup`;

export const logoutUrl = `${baseURL}/api/v1/auth/google/logout`;

export const chromeExtensionUrl = `${extensionUrl}`;