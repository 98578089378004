import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function AnimateComparison(props) {
	const ref = useRef(null);
	const [hasAnimated, setHasAnimated] = useState(false);

	useEffect(() => {
		const element = ref.current;

		if (element && !hasAnimated) {
			// Set initial overflow to hidden
			gsap.set(element, { overflow: "hidden" });

			gsap.timeline({
				scrollTrigger: {
					trigger: element,
					start: "top center",
					end: "bottom center",
					onEnter: () => {
						gsap.to(ref.current.children, {
							x: 0,
							stagger: 0.03,
							delay: 0.5,
							duration: 0.5,
						});
						// Set overflow to scrollable after the animation
						gsap.set(element, { overflow: "auto" });
					},
					once: true,
				},
			});
		}

		setHasAnimated(true);
	}, [hasAnimated]);

	return <div style={{ overflow: "hidden" }} {...props} ref={ref}></div>;
}
