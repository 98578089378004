import { useEffect, useState } from "react";

const Navigation = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [nav, setNav] = useState(false);
  const showNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 200) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);
  return (
    <nav
      className="navbar fixed-top shadow-sm navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-lg-5"
      style={{ display: visible ? "flex" : "none" }}
    >
      <a href="index.html" className="navbar-brand ml-lg-3">
        <h2 className="m-0 display-5">
          <span className="text-primary logo">{data.name}</span>
        </h2>
      </a>
      <button
        type="button"
        className="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        onClick={showNav}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={
          nav
            ? "collapse navbar-collapse px-lg-3 show"
            : "collapse navbar-collapse px-lg-3"
        }
        id="navbarCollapse"
      >
        <div className="navbar-nav m-auto py-0">
          <a href="#home" className="nav-item nav-link">
            Home
          </a>
          <a href="/blogs" className="nav-item nav-link">
            Blogs
          </a>
          {data.aboutMe && 
            <a href="#about" className="nav-item nav-link">
              About
            </a>
          }
          {(data.education?.length > 0 && Object.keys(data.education[0]).length > 0 || data.workExperience?.length > 0 && Object.keys(data.workExperience[0]).length > 0) && 
            <a href="#qualification" className="nav-item nav-link">
              Quality
            </a>
          }
          {data.skills?.length > 0 && Object.keys(data.skills[0]).length > 0 && 
            <a href="#skills" className="nav-item nav-link">
              Skill
            </a>
          }
          {data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 && 
            <a href="#testimonial" className="nav-item nav-link">
              Recommendations
            </a>
          }
          {data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 && 
            <a href="#projects" className="nav-item nav-link">
              Projects
            </a>
          }
          <a href="#contact" className="nav-item nav-link">
            Contact
          </a>
        </div>
        <a
          href="#contact"
          className="btn btn-outline-primary d-none d-lg-block"
        >
          Hire Me
        </a>
      </div>
      {/* <i className="fa fa-2x fa-angle-down text-dark scroll-to-bottom"></i> */}

      {visible && <a href="#home" className="btn btn-outline-dark px-0 back-to-top d-inline">
        <i className="fa fa-angle-double-up"></i>
      </a>}
    </nav>
  );
};
export default Navigation;
