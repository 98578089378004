const Awards = ({data})=>{
    return(
        <section className="section" id="awards">
        <div className="container text-center">
          <h1 className="section-title mb-4">Awards</h1>
          <div className="pricing-wrapper all-awards">
            {data.awards.map((award, id) => {
              return (
                <div key={id} className="pricing-card">
                  <div className="pricing-card-header">
                    <h1>&#127942;</h1>
                    <h3>{award.title}</h3>
                    <span className="display-6">
                      {award.institute} - {award.date}
                    </span>
                  </div>
                  <div className="pricing-card-body mt-2">
                    <p>{award.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    )
}

export default Awards;