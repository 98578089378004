const Education = ({data}) => {
  return (
    <div className="col-md-6 wow fadeInRight">
      <h2 className="fw-normal">Education</h2>
      <ul className="timeline education mt-4 pr-md-5">
        {data.education.map((ed,idx) => {
          return (
            <li key={idx}>
               <div className={ed.endDate==='Present'?"present title":"title"}>
                {ed.startDate}-{ed.endDate}
              </div>
              <div className="details">
                <h5 className="text-body">{ed.qualification}</h5>
                <small className="fg-theme">{ed.instituteName}</small>
                <p>{ed.description}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Education;
