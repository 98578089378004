import $ from 'jquery'
const Header = ({data}) => {
  const toggleClick = (e) => {
    var $this = $(e.target),
      sect = $("#" + $this.data("section")),
      current_sect = $(".section.active");
    if (sect.length === 1) {
      if (
        sect.hasClass("active") === false &&
        $("#customId").hasClass("section-switching") === false
      ) {
        $("#customId").addClass("section-switching");
        if (sect.index() < current_sect.index()) {
          $("#customId").addClass("up");
        } else {
          $("#customId").addClass("down");
        }
        setTimeout(function () {
          $("#customId").removeClass("section-switching up down");
        }, 2500);
        setTimeout(function () {
          current_sect.removeClass("active");
          sect.addClass("active");
        }, 1250);
        if (sect.hasClass("border-d")) {
          $("#customId").addClass("border-dark");
        } else {
          $("#customId").removeClass("border-dark");
        }
      }
    }
  };
  return (
<section id="intro" className="section section-main active">
            <div className="container-fluid">
              <div className="v-align">
                <div className="inner">
                  <div className="intro-text">
                    <h1>I'm {data.name}</h1>

                    {data.description?<p>{data.description}</p>:<></>}

                    <div className="intro-btns">
                      <a
                        href="#about"
                        className="btn-custom section-toggle"
                        data-section="about"
                        onClick={toggleClick}
                      >
                        Discover More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  );
};

export default Header;
