import { useEffect } from "react";
import fillContent from './functions/fillContent';
import filterTag from "./functions/filterTag";
import About from "./components/about";
import Contact from "./components/contact";
import Education from "./components/education";
import Experience from "./components/experience";
import Footer from "./components/footer";
import Header from "./components/header";
import Navigation from "./components/navigation";
import Projects from "./components/projects";
import Recommendation from "./components/recommendation";
import Skills from "./components/skills";
import "./css/bootstrap.min.scss";
import "./css/animate.scss";
import "./css/slick.scss";
import "./css/themify-icons.scss";
import "./css/style.scss";

function Theme8({ data, subdomain }) {
  useEffect(() => {
    let metaTags = document.getElementsByTagName("meta");
    let description = filterTag(metaTags, "description");
    let author = filterTag(metaTags, "author");

    fillContent(author, data.name);
    fillContent(description, data.about);
    document.title = data.name + " | SitesGPT";
  }, [data.name,data.about]);
  return (
    <div id="theme8">
      <Navigation data={data} subdomain={subdomain} />

      <Header data={data} />
      <About data={data} />


      {data.workExperience?.length>0 && Object.keys(data.workExperience[0]).length > 0?<Experience data={data} />:<></>}
      {data.education?.length>0 && Object.keys(data.education[0]).length > 0?<Education data={data} />:<></>}

      {data.projects?.length>0 && Object.keys(data.projects[0]).length > 0?<Projects data={data} />:<></>}
      {data.skills?.length>0 && Object.keys(data.skills[0]).length > 0?<Skills data={data} />:<></>}

      {data.recommendations?.length>0 && Object.keys(data.recommendations[0]).length > 0?<Recommendation data={data} />:<></>}

      <Contact data={data} />

      <Footer data={data} />
    </div>
  );
}

export default Theme8;
