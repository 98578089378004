const Skills = ({ data }) => {
  return (
    <section lass="section pt-0" id="skills">
      <div className="position-relative d-flex align-items-center justify-content-center">
        <h1
          className="display-1 text-uppercase text-white"
          style={{ WebkitTextStroke: "1px #dee2e6" }}
        >
          Skills
        </h1>
        <h1 className="position-absolute text-uppercase text-primary">
          My Skills
        </h1>
      </div>
      <div
        className="col-lg-6 wow fadeInUp container text-center"
        data-wow-delay="0.1s"
        style={{
          visibility: "visible",
          animationDelay: "0.1s",
          animationName: "fadeInUp",
        }}
      >
        <div className="p-0">
          <div className="row gy-5 gx-4 all-skills">
            {data.skills.map((skill, idx) => (
              <div className="skill mb-4 ml-4" key={idx}>
                <div className="d-flex justify-content-between">
                  <em className="skill-text">{skill.skill}</em>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
