import { useEffect } from "react";
import filterTag from "./functions/filterTag";
import fillContent from "./functions/fillContent";
import Navigation from "./components/navigation";
import Header from "./components/header";
import About from "./components/about";
import Skills from "./components/skills";
import Experience from "./components/experience";
import Education from "./components/education";
import Awards from "./components/awards";
import HireMe from "./components/hireme";
import Recommendation from "./components/recommendation";
import Projects from "./components/projects";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Languages from "./components/languages";
import "./css/meyawo.scss";
// import "./css/meyawo.modules.css";
import "./css/themify-icons.scss";
// import "./css/themify-icons.css";

function Theme5({ data, subdomain }) {
	useEffect(() => {
		let metaTags = document.getElementsByTagName("meta");
		let description = filterTag(metaTags, "description");
		let author = filterTag(metaTags, "author");

		fillContent(author, data.name);
		fillContent(description, data.about);
		document.title = data.name + " | SitesGPT";
	}, [data.name, data.about]);

	return (
		<div
			data-spy="scroll"
			data-target=".navbar"
			data-offset="40"
			id="theme5"
		>
			<Navigation data={data} />
			<Header data={data} />
			<About data={data} subdomain={subdomain} />
			{data.workExperience?.length > 0 &&
			Object.keys(data.workExperience[0]).length > 0 ? (
				<Experience data={data} />
			) : (
				<></>
			)}
			{data.education?.length > 0 &&
			Object.keys(data.education[0]).length > 0 ? (
				<Education data={data} />
			) : (
				<></>
			)}
			{data.languages?.length > 0 &&
			Object.keys(data.languages[0]).length > 0 ? (
				<Languages data={data} />
			) : (
				<></>
			)}
			{data.skills?.length > 0 &&
			Object.keys(data.skills[0]).length > 0 ? (
				<Skills data={data} />
			) : (
				<></>
			)}
			{data.awards?.length > 0 &&
			Object.keys(data.awards[0]).length > 0 ? (
				<Awards data={data} />
			) : (
				<></>
			)}
			<HireMe data={data} />
			{data.recommendations?.length > 0 &&
			Object.keys(data.recommendations[0]).length > 0 ? (
				<Recommendation data={data} />
			) : (
				<></>
			)}
			{data.projects?.length > 0 &&
			Object.keys(data.projects[0]).length > 0 ? (
				<Projects data={data} />
			) : (
				<></>
			)}
			<Contact data={data} />
			<Footer data={data} />
		</div>
	);
}

export default Theme5;
