import styles from "../Styles/newLanding.module.css";
import { useSelector } from "react-redux";
import ExtensionIcon from "@mui/icons-material/Extension";
import LaunchIcon from '@mui/icons-material/Launch';
import { loginUrl, chromeExtensionUrl } from "../service/api";
import Image from "./Image";
import AnimateHeading from "./AnimateHeading";
import AnimateMarquee from "./AnimateMarquee";

const HeroImages = [
	"./Assets/image1.png",
	"./Assets/image2.png",
	"./Assets/image3.png",
	"./Assets/image4.png",
	"./Assets/image5.png",
	"./Assets/image6.png",
	"./Assets/image7.png",
	"./Assets/image8.png",
	"./Assets/image9.png",
];

export default function Hero() {
	const user = useSelector((state) => state.user);
	// console.log(user)
	const subdomain = (user.user?.json.profileLink)?.split("/")?.reverse()[1];
	const heroImagesDuration = parseInt(
		getComputedStyle(document.body)
			.getPropertyValue("--heroImages-duration")
			.replace("s", "")
	);

	return (
		<div className={styles.hero}>
			<div className={styles.tagline}>
				<AnimateHeading text={"BUILD YOUR OWN PERSONAL WEBSITE IN LESS THAN 2 MINUTES"} className={styles.heading}/>
				<h2 className={styles.subHeading}>
				No Coding Required! Our Smart Extension does everything website, content and blogs! Yes Everything Automatically!{" "}
				</h2>
				{!!user.user ? (
					!!user.online ? (
						<a
						href={`https://${subdomain}.sitesgpt.co.in`}
						className={
							styles.extension +
							" " +
							styles.calltoaction +
							" btn-hover"
						}
						>
						Go to your website
						<LaunchIcon />
						</a>
					) : (
						<a
						href={`${chromeExtensionUrl}`}
						className={
							styles.extension +
							" " +
							styles.calltoaction +
							" btn-hover"
						}
						>
						Download Extension
						<ExtensionIcon />
						</a>
					)
					) : (
					<a
						href={`${loginUrl}`}
						className={styles.calltoaction + " btn-hover"}
					>
						Get Started for FREE!!!
					</a>
				)}

			</div>
			<AnimateMarquee className={styles.heroImagesContainer}>
				<div className={styles.heroImagesMarqueeContainer}>
					<div className={styles.heroImages}>
						{HeroImages.map((image, index) => {
							return (
								<Image
									src={image}
									key={index}
									alt="heroImage"
								/>
							);
						})}
					</div>
					<div className={styles.heroImages}>
						{HeroImages.map((image, index) => {
							return (
								<Image
									src={image}
									key={index}
									alt="heroImage"
								/>
							);
						})}
					</div>
				</div>
				<div className={styles.heroImagesMarqueeContainer}>
					<div className={styles.heroImages}>
						{HeroImages.map((image, index) => {
							return (
								<Image
									src={image}
									key={index}
									alt="heroImage"
								/>
							);
						})}
					</div>
					<div className={styles.heroImages}>
						{HeroImages.map((image, index) => {
							return (
								<Image
									src={image}
									key={index}
									alt="heroImage"
								/>
							);
						})}
					</div>
				</div>
				<div className={styles.heroImagesMarqueeContainer}>
					<div className={styles.heroImages}>
						{HeroImages.map((image, index) => {
							return (
								<Image
									src={image}
									key={index}
									alt="heroImage"
								/>
							);
						})}
					</div>
					<div className={styles.heroImages}>
						{HeroImages.map((image, index) => {
							return (
								<Image
									src={image}
									key={index}
									alt="heroImage"
								/>
							);
						})}
					</div>
				</div>
			</AnimateMarquee>
		</div>
	);
}
