const Education = ({ data }) => {
  return (
    <div className="row education">
      <div className="three columns header-col">
        <h1>
          <span>Education</span>
        </h1>
      </div>

      <div className="nine columns main-col">
        {data.education.map((ed, idx) => (
          <div className="rep-col" key={idx}>
            <img
              src={
                ed.instituteLogo ||
                "https://static.vecteezy.com/system/resources/thumbnails/000/350/330/small/Education__28171_29.jpg"
              }
              className="resume-img"
              alt={ed.instituteName}
            />
            <div className="row item">
              <div className="twelve columns">
                <h3>{ed.instituteName}</h3>
                <p className="info">
                  {ed.description}
                  <span>&bull;</span>{" "}
                  <em className="date">
                    {ed.startDate}-{ed.endDate}
                  </em>
                </p>
                <p></p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
