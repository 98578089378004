import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <section
      className="section bg-primary position-relative testimonial-bg-shapes"
      id="testimonial"
    >
      <div className="container">
        <div>
          <div className="text-center">
            <h2 className="section-title text-white mb-5">Testimonials</h2>
          </div>
          <Carousel
            autoPlay
            infiniteLoop
            showArrows={false}
            showStatus={false}
            showThumbs={false}
          >
            {data.recommendations.map((recommendation, idx) => (
              <div className="text-center testimonial-content mb-4" key={idx}>
                <i className="ti-quote-right text-white icon mb-4 d-inline-block"></i>
                <p className="text-white mb-4">
                  {recommendation.recommendationText}
                </p>
                <div>
                  <img
                    className="img-fluid rounded-circle mb-4 d-inline-block"
                    src={
                      recommendation.profileImg ||
                      "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                    }
                    style={{
                      height: "80px",
                      width: "80px",
                    }}
                    alt="client"
                  />
                  <h4 className="text-white">{recommendation.name}</h4>
                  <h6 className="text-white">{recommendation.description}</h6>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>

      <img
        src={require("../images/backgrounds/map.png")}
        alt="map"
        className="img-fluid bg-map"
      />
      <img
        src={require("../images/illustrations/dots-group-v.png")}
        alt="bg-shape"
        className="img-fluid bg-shape-1"
      />
      <img
        src={require("../images/illustrations/leaf-orange.png")}
        alt="bg-shape"
        className="img-fluid bg-shape-2"
      />
      <img
        src={require("../images/illustrations/dots-group-sm.png")}
        alt="bg-shape"
        className="img-fluid bg-shape-3"
      />
      <img
        src={require("../images/illustrations/leaf-pink-round.png")}
        alt="bg-shape"
        className="img-fluid bg-shape-4"
      />
      <img
        src={require("../images/illustrations/leaf-cyan.png")}
        alt="bg-shape"
        className="img-fluid bg-shape-5"
      />
    </section>
  );
};

export default Recommendation;
