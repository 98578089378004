const Projects = ({ data }) => {
  return (
    <section className="projects section pt-0" id="portfolio">
      <div className="container hoc clear">
        <div className="row mb-5 text-center">
          <div className="col-lg-6 col-12 mx-auto ">
            <div className="section-title mb-md-4">
              <h2>
                My{" "}
                <strong>
                  Selected
                  <svg
                    className=""
                    width="198"
                    height="21"
                    viewBox="0 0 198 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 18.7327C13.8854 9.74093 29.4859 8.69377 43.6964 6.03115C65.1095 2.01897 86.6266 1.40829 108.405 2.01061C137.628 2.81881 166.558 7.3721 195.577 11.0296"
                      stroke="#FF5733"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </strong>{" "}
                projects
              </h2>
            </div>
          </div>
        </div>
        <ul className="nospace group elements elements-three">
          {data.projects.map((project, idx) => (
            <li className="one_third" key={idx}>
              <article>
                <h4>{project.projectName}</h4>
                <p
									style={{
										display: "block",
										maxHeight: "8em",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{project.description}
								</p>
                {project.projectLink && <a href={project.projectLink}>
                  <i className="fa fa-arrow-circle-right"></i>
                </a>}
              </article>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Projects;

