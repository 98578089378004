import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function AnimatedNumbers({ endValue }) {
	const ref = useRef(null);
	const [hasAnimated, setHasAnimated] = useState(false);

	useEffect(() => {
		const element = ref.current;

		if (element && !hasAnimated) {
			gsap.timeline({
				scrollTrigger: {
					trigger: element,
					start: "top bottom",
					end: "bottom center",
					onEnter: () => {
						gsap.to(element, {
							duration: 1,
							innerHTML: endValue + " +",
							snap: "innerHTML",
						});
					},
					once: true,
				},
			});

			gsap.set(element, { innerHTML: 0 });
		}

		setHasAnimated(true);
	}, [hasAnimated]);

	return (
		<h1 ref={ref}>
			0
		</h1>
	);
}
