import { useEffect } from "react";
import "./css/bootstrap.min.scss";
import "./css/font-awesome.min.scss";
import "./css/bootstrap.scss";
import "./css/style.scss";
import Header from "./components/header";
import About from "./components/about";
import Skills from "./components/skills";
import Languages from "./components/languages";
import Experience from "./components/experience";
import Education from "./components/education";
import Recommendation from "./components/recommendation";
import Projects from "./components/projects";
import Awards from "./components/awards";
import HireMe from "./components/hireme";
import filterTag from "./functions/filterTag";
import fillContent from "./functions/fillContent";
// import "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css";

function Theme4({ data, subdomain }) {
  useEffect(() => {
    let metaTags = document.getElementsByTagName("meta");
    let description = filterTag(metaTags, "description");
    let author = filterTag(metaTags, "author");

    fillContent(author, data.name);
    fillContent(description, data.about);
    document.title = data.name;
  }, [data.name, data.about]);

  return (
    <div id="theme4">
      <Header data={data} subdomain={subdomain} />

      {data.aboutMe ? <About data={data} /> : <></>}

      {data.skills?.length > 0 && Object.keys(data.skills[0]).length > 0 ? <Skills data={data} /> : <></>}

      {data.languages?.length > 0 && Object.keys(data.languages[0]).length > 0 ? <Languages data={data} /> : <></>}
      <section id="resume-sec">
        <div className="container">
          {data.workExperience?.length > 0 && Object.keys(data.workExperience[0]).length > 0 ? <Experience data={data} /> : <></>}
          {data.education?.length > 0 && Object.keys(data.education[0]).length > 0 ? <Education data={data} /> : <></>}
        </div>
      </section>
      {data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 ? <Recommendation data={data} /> : <></>}

      {data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 ? <Projects data={data} /> : <></>}

      {data.awards?.length > 0 && Object.keys(data.awards[0]).length > 0 ? <Awards data={data} /> : <></>}

      <HireMe data={data} />
    </div>
  );
}

export default Theme4;
