import styles from "../Styles/newLanding.module.css";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as Logo } from "./logo.svg";
import AnimateComparison from "./AnimateComparison";

export default function Comparison() {
	return (
		<AnimateComparison className={styles.comparisonTable}>
			<table>
				<thead>
					<tr>
						<th></th>
						<th>
							{/* <img src="./Assets/logo.png"/> */}
							<Logo className={styles.logoimg} />
						</th>
						<th>
							<img src="./Assets/wix.png" style={{mixBlendMode: "multiply"}}/>
						</th>

						<th>
							<img src="./Assets/squarespace.png" style={{mixBlendMode: "multiply"}}/>

						</th>
						<th>
							<img src="./Assets/wordpress.png" style={{mixBlendMode: "multiply"}}/>
						</th>
					</tr>
				</thead>
				<tbody>
					
					<tr>
						<td>Create your structure</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Automatically make your content</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Automatically create personalized blogs</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Automatically create resume</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Lock in to platform</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
					</tr>
					<tr>
						<td>Automatically update content</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Beautiful templates</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Make your website in 2 minutes</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Increase your visibility from day 0</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Sell your gig directly from fiverr</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Fetch your client testimonials</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
					<tr>
						<td>Downloadable HTML, CSS and JS file of your website</td>
						<td>
							<CheckCircleRoundedIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
						<td>
							<CloseIcon />
						</td>
					</tr>
				</tbody>
			</table>
		</AnimateComparison>
	);
}
