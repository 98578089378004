import { useEffect } from "react";
import fillContent from "./functions/fillContent";
import filterTag from "./functions/filterTag";
import About from "./components/about";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Header from "./components/header";
import Navigation from "./components/navigation";
import Projects from "./components/projects";
import Recommendation from "./components/recommendation";
// import Blog from "./components/blog";
import "./css/bootstrap.scss";
import "./css/minibar.virtual.scss";
import "./css/themify-icons.scss";
import "./css/virtual.scss";
import "./css/maicons.scss";
import "./css/jquery.fancybox.min.scss";
import Skills from "./components/skills";
import Languages from "./components/languages";
// import "./js/minibar-virtual.js"

function Theme7({ data, subdomain }) {
	useEffect(() => {
		let metaTags = document.getElementsByTagName("meta");
		let description = filterTag(metaTags, "description");
		let author = filterTag(metaTags, "author");

		fillContent(author, data.name);
		fillContent(description, data.about);
		document.title = data.name + " | SitesGPT";
	}, [data.name, data.about]);

	console.log("data", data);
	return (
		<div id="theme7">
			<div className="theme-red">
				<Navigation data={data} />
				<div className="vg-main-wrapper">
					<Header data={data} />

					{data.recommendations?.length > 0 &&
					Object.keys(data.recommendations[0]).length > 0 ? (
						<Recommendation data={data} />
					) : (
						<></>
					)}
					{data && (
						<About data={data} subdomain={subdomain} />
					)}
					{data.projects?.length > 0 &&
					Object.keys(data.projects[0]).length > 0 ? (
						<Projects data={data} />
					) : (
						<></>
					)}

					<Contact data={data} />

					<Footer data={data} />
				</div>
			</div>
		</div>
	);
}

export default Theme7;
