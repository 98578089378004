export default function Footer(){
    return <footer style={{
        textAlign: "center",
        padding: "1em 0",
        marginTop: "72px",
        fontSize: "0.8em",
    }}>
        ©️All copyrights reserved to SitesGPT, <a href="/privacy-policy"><u>Privacy Policy</u></a>
        &nbsp;made with ❤️ by SitesGPT
        <br/>
        Feedbacks? Contact <a href="https://www.linkedin.com/in/devsm/"><u>Shreyan Mehta</u></a>
    </footer>
}