const Interests = ({ data }) => {
	const titlesList = Object.keys(data.interests);

	return (
		<section id="interests" className="section interests-section border-d">
			<div className="section-block interests-block">
				<div className="container-fluid">
					<div className="section-header">
						<h2>
							My <strong className="color">Interests</strong>
						</h2>
					</div>
				</div>

				{titlesList.map((title, idx) => (
					<div
						className="container-fluid"
						key={idx}
						style={{
							margin: "5rem 0",
						}}
					>
						<div className="section-header">
							<h2 style={{ margin: "2rem 0"}}>
								{title}
							</h2>
						</div>
						<div
							style={{
								display: "grid",
								gridTemplateColumns:
									"repeat(auto-fit, minmax(25rem, 1fr))",
							}}
						>
							{data.interests[title].map((interest, idx) => (
								<a
									href={interest.link}
									className="skill col"
									key={idx}
								>
									<div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "1rem"
                                    }}>
										<img
											src={interest.picture? interest.picture: `Assets/icon-interests.svg`}
											alt={interest.name}
											style={{ height: "2rem" }}
										/>
										<h6>{interest.name}</h6>
									</div>
									<small>{interest.members}</small>
									<small>{interest.followers}</small>
								</a>
							))}
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export default Interests;
