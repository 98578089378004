const Projects = ({ data }) => {
  return (
    <section id="portfolio">
      <div className="row">
        <div>
          <h1>Check Out Some of My Projects.</h1>
        </div>
        <div id="portfolio-wrapper">
          {data.projects.map((project, idx) => (
            <div id={"modal-" + project.name} className="popup-modal" key={idx}>
              {/* <img
                className="scale-with-grid proj-img"
                src={
                  project.photo ||
                  "https://png.pngtree.com/png-vector/20220706/ourmid/pngtree-project-management-png-image_5687733.png"
                }
                alt=""
              /> */}

              <div className="description-box">
                <h2>{project.projectName}</h2>
                  <p className="proj-desc"
									  style={{
										  display: "block",
										  maxHeight: "3.5em",
										  overflow: "hidden",
										  textOverflow: "ellipsis",
									  }}
								  >
									  {project.description}
								  </p>
              </div>

              {project.projectLink && (
                <div className="link-box">
                  <a href={project.projectLink}>Details</a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
