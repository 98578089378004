const Footer = ({data})=>{
    return(
      <footer className="bg-dark footer-section">
      <div className="p-0">
        <p className="text-center text-white mb-0 pb-2">
        © {new Date().getFullYear()} All rights reserved by {data.name}
      </p>
      </div>
    </footer>
    )
}

export default Footer;