import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Recommendation = ({ data }) => {
  return (
    <div className="vg-page page-testimonial" id="testimonial">
      <div className="container">
        <h1 className="text-center fw-normal wow fadeInUp">
          What Clients are Saying
        </h1>
        <div className=" mt-5 wow fadeInUp">
          <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
            {data.recommendations.map((recommendation, idx) => {
              return (
                <div className="item mb-3" key={idx}>
                  <div className="caption">
                    <div className="testi-content">
                      {recommendation.recommendationText}
                    </div>
                    <div className="testi-info d-block w-100 mx-auto">
                      <div className="thumb-profile mx-auto">
                        <img
                          src={
                            recommendation.profileImg ||
                            "https://static.vecteezy.com/system/resources/previews/007/296/443/original/user-icon-person-icon-client-symbol-profile-icon-vector.jpg"
                          }
                          alt=""
                        />
                      </div>
                      <div className="tagline">
                        <h5 className="mb-0">{recommendation.name}</h5>
                        <h6 className="mb-0">{recommendation.description}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Recommendation;
