import $ from "jquery";

const Navigation = ({ data }) => {
	const toggleClick = (e) => {
		var $this = $(e.target),
			sect = $("#" + $this.data("section")),
			current_sect = $(".section.active");
		if (sect.length === 1) {
			if (
				sect.hasClass("active") === false &&
				$("#customId").hasClass("section-switching") === false
			) {
				$("#customId").addClass("section-switching");
				if (sect.index() < current_sect.index()) {
					$("#customId").addClass("up");
				} else {
					$("#customId").addClass("down");
				}
				setTimeout(function () {
					$("#customId").removeClass("section-switching up down");
				}, 2500);
				setTimeout(function () {
					current_sect.removeClass("active");
					sect.addClass("active");
				}, 1250);
				if (sect.hasClass("border-d")) {
					$("#customId").addClass("border-dark");
				} else {
					$("#customId").removeClass("border-dark");
				}
			}
		}
	};
	return (
		<div className="menu">
			<div className="inner">
				<ul className="menu-items">
					<li>
						<a
							href="#"
							className="section-toggle"
							data-section="intro"
							onClick={toggleClick}
						>
							Home
						</a>
					</li>
					
					<li>
						<a
							href="#about"
							className="section-toggle"
							data-section="about"
							onClick={toggleClick}
						>
							About & Resume
						</a>
					</li>

					{data?.skills?.length > 0 && Object.keys(data.skills[0])?.length > 0  && (
						<li>
							<a
								href="#skills"
								className="section-toggle"
								data-section="skills"
								onClick={toggleClick}
							>
								Skills
							</a>
						</li>
					)}

					{data?.languages?.length > 0 && Object.keys(data.languages[0])?.length > 0 && (
						<li>
							<a
								href="#languages"
								className="section-toggle"
								data-section="languages"
								onClick={toggleClick}
							>
								Languages
							</a>
						</li>
					)}

					{data?.awards?.length > 0 && Object.keys(data.awards[0])?.length > 0 && (
						<li>
							<a
								href="#awards"
								className="section-toggle"
								data-section="awards"
								onClick={toggleClick}
							>
								Awards
							</a>
						</li>
					)}

					{(data.education?.length > 0 ||
						data.recommendations?.length > 0) && Object.keys(data.education[0])?.length > 0  && (
						<li>
							<a
								href="#education"
								className="section-toggle"
								data-section="education"
								onClick={toggleClick}
							>
								Education
							</a>
						</li>
					)}

					{
						data.workExperience?.length > 0 && Object.keys(data.workExperience[0])?.length > 0 && (
							<li>
								<a
									href="#experience"
									className="section-toggle"
									data-section="experience"
									onClick={toggleClick}
								>
									Experience
								</a>
							</li>
						)
					}

					{data.projects?.length > 0 && Object.keys(data.projects[0])?.length > 0 && (
						<li>
							<a
								href="#projects"
								className="section-toggle"
								data-section="projects"
								onClick={toggleClick}
							>
								Projects
							</a>
						</li>
					)}
					{!!data.interests && Object.keys(data.interests)?.length > 0 && (
						<li>
							<a
								href="#interests"
								className="section-toggle"
								data-section="interests"
								onClick={toggleClick}
							>
								Interests
							</a>
						</li>
					)}

					<li>
						<a
							href="/blogs"
							className="section-toggle"
							data-section="intro"
							onClick={toggleClick}
						>
							Blogs
						</a>
					</li>
					
					<li>
						<a
							href="#contact"
							className="section-toggle"
							data-section="contact"
							onClick={toggleClick}
						>
							Contact
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
};
export default Navigation;
