const Projects = ({ data }) => {
  return (
    <section className="section" id="projects">
      <div className="container text-center">
        <h1 className="mb-4">Projects</h1>
        <div className="section-title">
          {data.projects.map((project, id) => {
            return (
              <div key={id} className="blog-card">
                <div className="blog-card-body">
                  <h2 className="blog-card-title mb-0">{project.projectName}</h2>
                  <p className="mb-2 mt-0">
                    ({project.startDate}-{project.endDate})
                  </p>
                  <p>{project.description}</p>

                 {project.projectLink && <a href={project.projectLink} className="blog-card-link">
                    See Live Demo <i className="ti-angle-double-right"></i>
                  </a>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
