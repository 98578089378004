import Navigation from "./navigation";
const Header = ({data}) => {
  return (
    <header id="home">
      <Navigation data={data}/>

      <div className="row banner">
        <div className="banner-text">
          <h1 className="responsive-headline">I'm {data.name}</h1>
          {data.description?<h3 className="description">{data.description}</h3>:<></>}
          <hr />
          <ul className="social">
            {data.fbLink && <li>
              <a href={data.fbLink}>
                <i className="fa fa-facebook"></i>
              </a>
            </li>}
           {data.twitterLink && <li>
              <a href={data.twitterLink}>
                <i className="fa fa-twitter"></i>
              </a>
            </li>}
           {data.googleLink && <li>
              <a href={data.googleLink}>
                <i className="fa fa-google-plus"></i>
              </a>
            </li>}
           {data.profileLink && <li>
              <a href={data.profileLink}>
                <i className="fa fa-linkedin"></i>
              </a>
            </li>}
           {data.instaLink && <li>
              <a href={data.instaLink}>
                <i className="fa fa-instagram"></i>
              </a>
            </li>}
           {data.dribbleLink && <li>
              <a href={data.dribbleLink}>
                <i className="fa fa-dribbble"></i>
              </a>
            </li>}
          {data.skypeLink &&  <li>
              <a href={data.skypeLink}>
                <i className="fa fa-skype"></i>
              </a>
            </li>}
          </ul>
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};

export default Header;
