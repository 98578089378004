const Skills = ({data})=>{
    return(
        <section className="section pt-0" id="skills">
        <div
          className="col-lg-6 wow fadeInUp container text-center"
          data-wow-delay="0.1s"
          style={{
            visibility: "visible",
            animationDelay: "0.1s",
            animationName: "fadeInUp",
          }}
        >
          <h1 className="mb-4">My Skills</h1>
          <div className="p-0">
            <div className="row all-skills">
              {data.skills.map((skill, id) => {
                return (
                  <div key={id} className="skill mb-4 ml-4">
                    <div>
                      <h4 className="font-weight-bold ">{skill.skill}</h4>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    )
}

export default Skills;