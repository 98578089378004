import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
	name: "user",
	initialState: {
		user: null,
        online: false,
        subdomain: null,
        theme: null,
        email: null,
        id: null,
        picture: null,
	},
	reducers: {
		login: (state, action) => {
			state.user = action.payload;
            state.online = !!action.payload?.json;
            state.subdomain = action.payload?.json?.profileLink?.split("/").reverse()[1];
            state.theme = action.payload?.theme;
            state.email = action.payload?.email;
            state.id = action.payload?.id;
            state.picture = action.payload?.picture;
		},
		logout: (state) => {
			state.user = null;
            state.online = false;
            state.subdomain = null;
            state.theme = null;
            state.email = null;
            state.id = null;
            state.picture = null;
		},
	},
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
