const Skills = ({ data }) => {
  return (
    <div className="row skill">
      <div className="three columns header-col">
        <h1>
          <span>Skills</span>
        </h1>
      </div>

      <div className="nine columns main-col">
        <div className="all-bars">
          <ul className="skills" style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(15rem, 1fr))",
            columnGap: "1rem",
          }}>
            {data.skills.map((skill, idx) => (
              <li key={idx} style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}>
                {/* <span className="bar-expand all"></span> */}
                <em>{skill.skill}</em>
                <small>{skill.edorsments}</small>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Skills;
