import fbImg from "../img/Social/facebook.png";
import linkedinImg from "../img/Social/linkedin.png";
import twitterImg from "../img/Social/twitter.png";
const About = ({ data }) => {
  return (
    <section id="about-sec">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-4 col-md-4 col-sm-4 wow bounceIn animated"
            data-wow-duration="1s"
            data-wow-delay=".2s"
          >
            <h1>WANT TO KNOW</h1>
            <h1>ABOUT ME ?</h1>
            <div className="color-strip"></div>
            {/* <div className="social-icon text-left">
              {data.fbLink && (
                <a href={data.fbLink}>
                  <img src={fbImg} alt="" />
                </a>
              )}
              {data.profileLink && (
                <a href={data.profileLink}>
                  <img
                    src={linkedinImg}
                    alt=""
                    
                  />
                </a>
              )}
              {data.twitterLink && (
                <a href={data.twitterLink}>
                  <img src={twitterImg} alt="" />
                </a>
              )}
            </div> */}
          </div>
          <div
            className="col-lg-8 col-md-8 col-sm-8 wow bounceIn animated"
            data-wow-duration="1s"
            data-wow-delay=".4s"
          >
            <p className="about-me">{data.aboutMe}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
