import $ from "jquery";
import { useEffect, useState } from "react";
const Navigation = ({data}) => {
  const [nav, setNav] = useState(false);
  const toggleNav = function () {
    setNav(!nav);
  };
  const backToTop = function () {
    $("html").animate(
      {
        scrollTop: 0,
      },
      200
    );
    return false;
  };

  const [float, setFloat] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 300) {
        setFloat(true);
      } else {
        setFloat(false);
      }
    });
  }, []);

  return (
    <div
      className={
        "navbar navbar-expand-lg navbar-dark sticky " +
        (float ? "floating" : "")
      }
      data-offset="500"
    >
      <div
        className="btn-back_to_top"
        style={float ? { visibility: "visible", transform: "translate(-0.5em, -5em)" } : { visibility: "hidden" }}
        onClick={backToTop}
      ><span className="ti-arrow-up"></span>
      </div>
      <div className="container">
        <a href="" className="navbar-brand">
          {data.name}
        </a>
        <button
          className={nav ? "navbar-toggler" : "navbar-toggler collapsed"}
          data-toggle="collapse"
          data-target="#main-navbar"
          aria-expanded="true"
          onClick={toggleNav}
        >
          <span className="ti-menu"></span>
        </button>
        <div
          className={
            nav
              ? "collapse navbar-collapse text-center show"
              : "collapse text-center navbar-collapse"
          }
          id="main-navbar"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="#home" className="nav-link" data-animate="scrolling">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="/blogs" className="nav-link" data-animate="scrolling">
                Blogs
              </a>
            </li>
            {data.aboutMe && (
            <li className="nav-item">
              <a href="#about" className="nav-link" data-animate="scrolling">
                About
              </a>
            </li>
            )}
           {data.projects?.length>0 && Object.keys(data.projects[0]).length > 0 && <li className="nav-item">
              <a
                href="#portfolio"
                className="nav-link"
                data-animate="scrolling"
              >
                Portfolio
              </a>
            </li>}
           {data.recommendations?.length>0 && Object.keys(data.recommendations[0]).length > 0 && <li className="nav-item">
              <a
                href="#testimonial"
                className="nav-link"
                data-animate="scrolling"
              >
                Testimonial
              </a>
            </li>}
            <li className="nav-item">
              <a href="#contact" className="nav-link" data-animate="scrolling">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Navigation;
