import { gsap } from "gsap";
import { useEffect, useRef } from "react";
import SplitType from "split-type";

export default function AnimateHeading(props) {
	const headingRef = useRef(null);
    const text = props.text;
    
    
	useEffect(() => {
        if (!headingRef.current) return;
		SplitType.create(headingRef.current, {
			types: "word",
			wordClass: "heading",
		});
		gsap.to(headingRef.current.children, {
			y: 0,
			stagger: 0.03,
			delay: 0.5,
			duration: 0.5,
		});
	}, [headingRef]);

	return <h1 ref={headingRef} {...props}>{text}</h1>;
}
