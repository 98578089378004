
const Header = ({ data, subdomain }) => {
	return (
		<div id="home-sec">
			<div className="overlay">
				<div className="container">
					<div className="row pad-top-bottom  move-me d-flex justify-content-between">
						<div className="col-lg-5 col-md-5 col-sm-5 text-center">
							<img
								className="main-imgs img-circle wow bounceIn animated"
								data-wow-duration="1s"
								data-wow-delay=".2s"
								alt=""
								src={data.profilePicture}
							/>
							<h1
								className="wow bounceIn animated name"
								data-wow-duration="1s"
								data-wow-delay=".4s"
							>
								{data.name}
							</h1>
							{data.description ? (
								<h2
									className="wow bounceIn animated description"
									data-wow-duration="1s"
									data-wow-delay=".6s"
								>
									{data.description}
								</h2>
							) : (
								<></>
							)}
							<a
								href={
									data.aboutMe
										? "#about-sec"
										: data.skills.length > 0
										? "#skill-sec"
										: "#"
								}
								className="btn custom-btn-one btn-lg wow bounceIn animated"
								data-wow-duration="1s"
								data-wow-delay=".8s"
							>
								EXPLORE
							</a>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-2 text-center  ">
							{data.aboutMe && (
								<a
									href="#about-sec"
									className="wow bounceIn animated"
									data-wow-duration="1s"
									data-wow-delay=".1s"
								>
									<i className="fa fa-info icon-round icon-round-active"></i>
									<h3
										className="wow bounceIn animated"
										data-wow-duration="1s"
										data-wow-delay=".2s"
									>
										ABOUT ME
									</h3>
								</a>
							)}
							
							{data.projects?.length > 0 && (
								<a
									href="#portfolio-sec"
									className="wow bounceIn animated"
									data-wow-duration="1s"
									data-wow-delay=".5s"
								>
									<i className="fa fa-recycle icon-round icon-round-active"></i>
									<h3
										className="wow bounceIn animated"
										data-wow-duration="1s"
										data-wow-delay=".6s"
									>
										PROJECTS
									</h3>
								</a>
							)}
							<a
								href="#hire-sec"
								className="wow bounceIn animated"
								data-wow-duration="1s"
								data-wow-delay=".7s"
							>
								<i className="fa fa-paper-plane-o icon-round"></i>
								<h3
									className="wow bounceIn animated"
									data-wow-duration="1s"
									data-wow-delay=".8s"
								>
									CONTACT ME
								</h3>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
