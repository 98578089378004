import { useEffect, useState } from "react";

const Navigation = ({ data }) => {
	const [navClick, setNavClick] = useState(false);
	const [navShow, setNavShow] = useState(false);
	const [float, setFloat] = useState(false);
	function showNav() {
		setNavClick(!navClick);
		setNavShow(!navShow);
		// $("ul.nav").toggleClass("show");
	}
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (document.documentElement.scrollTop > 100) {
				setFloat(true);
			} else {
				setFloat(false);
			}
		});
	}, []);
	return (
		<nav
			className={
				float ? "custom-navbar affix" : "custom-navbar affix-top"
			}
			// data-spy="affix"
			// data-offset-top="20"
		>
			<div className="container">
				<a className="logo" href="#home">
					{data.name}
				</a>
				<ul className={navShow ? "nav show" : "nav"}>
					<li className="item">
						<a className="link" href="#home">
							Home
						</a>
					</li>
					<li className="item">
						<a className="link" href="/blogs">
							Blogs
						</a>
					</li>
					{data.aboutMe && (
						<li className="item">
							<a className="link" href="#about">
								About
							</a>
						</li>
					)}
					{data.skills?.length > 0 && Object.keys(data.skills[0]).length > 0 && (
						<li className="item">
							<a className="link" href="#skills">
								Skills
							</a>
						</li>
					)}
					{data.workExperience?.length > 0 && Object.keys(data.workExperience[0]).length > 0 && (
						<li className="item">
							<a className="link" href="#experience">
								Experience
							</a>
						</li>
					)}
					{data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 && (
						<li className="item">
							<a className="link" href="#testimonial">
								Testimonial
							</a>
						</li>
					)}
					{data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 && (
						<li className="item">
							<a className="link" href="#projects">
								Projects
							</a>
						</li>
					)}
					<li className="item">
						<a className="link" href="#contact">
							Contact
						</a>
					</li>
				</ul>
				<button
					id="nav-toggle"
					onClick={showNav}
					className={
						navClick
							? "hamburger hamburger--elastic is-active"
							: "hamburger hamburger--elastic "
					}
				>
					<div className="hamburger-box">
						<div className="hamburger-inner"></div>
					</div>
				</button>
			</div>
		</nav>
	);
};
export default Navigation;
