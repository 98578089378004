const Education = ({ data }) => {
  return (
    <div
      className="row text-center wow bounceIn animated"
      data-wow-duration="1s"
      data-wow-delay=".2s"
    >
      <div className="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 text-center mb-4">
        <h1>EDUCATION</h1>
      </div>
      <div
        className="wow bounceIn animated edu-content"
        data-wow-duration="1s"
        data-wow-delay=".4s"
      >
        {data.education.map((ed, idx) => (
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" key={idx}>
            <div className="portfolio-sec-wrap">
              <img
                className="img-responsive"
                src={
                  ed.instituteLogo ||
                  "https://static.vecteezy.com/system/resources/thumbnails/000/350/330/small/Education__28171_29.jpg"
                }
                alt=""
              />
              <div className="overlay">
                <div className="portfolio-sec-inner">
                  <h3>
                    <a href={ed.instituteLink}>{ed.instituteName}</a>
                  </h3>
                  <span>{ed.qualification}</span>
                  <p>
                    {ed.startDate}-{ed.endDate}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
